<template>
  <el-form :model="forms" ref="forms">
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-steps style="max-width: 80%; margin: 0 auto; margin-bottom: 15px;" :active="step" align-center>
          <el-step title="1 选择对应半成品库房的货品批次号" />
          <el-step title="2 选择要入库的成品货品批次号" />
          <el-step title="3 录入成品入库货品基础信息" />
        </el-steps>
        <hr>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :xs="12" :sm="6" :md="6" :lg="4" :xl="4">
        <el-form-item label="操作类型" label-width="100px">
          <el-select v-model="forms.billType" disabled placeholder="操作类型" style="width: 140px">
            <el-option v-for="item in dict.billTypes" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="12" :sm="6" :md="6" :lg="3" :xl="3">
        <el-form-item label="" label-width="0px">
          <el-input v-model="forms.storeCode" disabled style="width:120px; margin-left: 50px;"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="12" :sm="6" :md="6" :lg="5" :xl="5">
        <el-form-item label="所属公司" label-width="100px">
          <el-tree-select ref="companys" v-model="forms.companyId" :data="companys" check-strictly
            @change="results = [];" :render-after-expand="false" style="max-width: 240px;"
            :disabled="results.length > 0" />
        </el-form-item>
      </el-col>
      <el-col :xs="12" :sm="6" :md="6" :lg="4" :xl="4">
        <el-form-item label="" label-width="0px">
          <el-input v-model="forms.channel" placeholder="入库的渠道" disabled
            style="width:120px; margin-left: 50px;"></el-input>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="6" :md="6" :lg="4" :xl="4" v-show="step > 2">
        <el-button type="primary" icon="plus" plain @click="submitEvent()" v-if="(id || '').length == 0 || (forms && forms.status != 1)">
          {{ id.length > 0 ? "审核入库" : "提交审核" }}</el-button>
        <el-button @click="$router.go(-1)">返回</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="10" v-show="step >= 2 && !id">
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="成品款号" label-width="100px">
          <el-autocomplete v-model="chooseSku.value" :fetch-suggestions="searchSkuAsync" placeholder="选择对应要入库的组装成品款式信息"
            @select="chooseSkuHandle" style="width:90%">
          </el-autocomplete>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="款式信息" label-width="100px">
          <el-input v-model="bound.detailCode" disabled style="width:160px;"></el-input>
          <el-input v-model="bound.modelCode" disabled style="width:140px; margin-left: 10px;"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10" v-show="step == 3">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <el-form-item label="入库成品名称" label-width="100px">
          <el-input v-model="bound.detailName" style="width:90%" maxlength="30" show-word-limit
            :disabled="id.length > 0"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <el-form-item label="单据编号" label-width="100px">
          <el-input v-model="bound.billCode" disabled style="width:200px"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10" v-show="step == 3">
      <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
        <el-form-item label="入库批次号" label-width="100px">
          <el-input v-model="bound.serialCode" @change="bound.brandSerialCode = bound.certNumber = bound.serialCode;"
            style="width:180px;" :disabled="id.length > 0"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
        <el-form-item label="证书号" label-width="100px">
          <el-input v-model="bound.certNumber" style="width:180px;" :disabled="id.length > 0"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="10" :lg="10" :xl="10">
        <el-form-item label="入库规格" label-width="100px">
          <el-input v-model="bound.unitName" style="width:250px;" :disabled="id.length > 0"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10" v-show="step == 3">
      <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
        <el-form-item label="入库品牌" label-width="100px">
          <el-input v-model="bound.brandCode" style="width:160px;" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
        <el-form-item label="品牌款号" label-width="100px">
          <el-input v-model="bound.brandModelCode" style="width:160px;" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="10" :lg="10" :xl="10">
        <el-form-item label="品牌批次号" label-width="100px">
          <el-input v-model="bound.brandSerialCode" style="width:160px;" disabled></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10" v-show="step == 3">
      <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
        <el-form-item label="入库工厂" label-width="100px">
          <el-input v-model="bound.factoryCode" style="width:160px;" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
        <el-form-item label="工厂款号" label-width="100px">
          <el-input v-model="bound.factoryModelCode" style="width:160px;" disabled></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10" v-show="step == 3">
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <el-form-item label="品牌使用费" label-width="100px">
          <el-input type="number" v-model="bound.useBrandPrice" style="width:140px;"
            :disabled="id.length > 0"></el-input>
          <el-input type="number" v-model="bound.unitPrice" style="width:140px; display: none;"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <el-form-item label="不含税采购价" label-width="100px">
          <el-input type="number" v-model="bound.noTaxPurchasePrice" style="width:140px;"
            :disabled="id.length > 0"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <el-form-item label="税额" label-width="100px">
          <el-input type="number" v-model="bound.taxPrice" style="width:140px;" :disabled="id.length > 0"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <el-form-item label="含税采购价" label-width="100px">
          <el-input type="number" v-model="bound.purchasePrice" style="width:140px;"
            :disabled="id.length > 0"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <el-form-item label="入库价格" label-width="100px">
          <el-input type="number" v-model="bound.productPrice" style="width:140px;"
            :disabled="id.length > 0"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <el-form-item label="标签价" label-width="100px">
          <el-input type="number" v-model="bound.labelPrice" style="width:140px;" :disabled="id.length > 0"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <el-form-item label="售卖价" label-width="100px">
          <el-input type="number" v-model="bound.sellPrice" style="width:140px;" :disabled="id.length > 0"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10" v-show="step == 3">
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <el-form-item label="入库数量" label-width="100px">
          <el-input type="number" v-model="bound.stockNumber" style="width:100px;" :disabled="id.length > 0"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <el-form-item label="入库重量" label-width="100px">
          <el-input v-model="bound.weight" style="width:160px;" :disabled="id.length > 0"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-form-item label="入库备注" label-width="100px">
          <el-input v-model="bound.remark" :disabled="id.length > 0"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10" v-show="step == 1">
      <el-col :span="18">
        <el-form-item label="组装的批次号" label-width="100px">
          <!-- <el-input v-model="bound.serialCodes" @keyup.enter="querySerialCodeEvent"
              placeholder="半成品批次号(如有多个可用逗号或者空格连接)" style=""></el-input> -->
          <el-autocomplete style="width: 95%" v-model="chooseBound.value" :fetch-suggestions="searchBoundAsync"
            placeholder="选择要加工的半成品库存" @select="chooseBoundHandle">
          </el-autocomplete>
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-button type="primary" @click="confirmEvent" style="margin-left: 15px;">加工确认</el-button>
      </el-col>
    </el-row>
  </el-form>
  <el-table :data="results" stripe size="small" style="width: 100%" v-show="step >= 1">
    <el-table-column>
      <el-table-column type="index" align="center" label="序号" width="45"> </el-table-column>
      <el-table-column prop="serialCode" label="货号" width="100" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="detailCode" label="系统款号(SKU)" width="125" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="brandModelCode" label="品牌款号" width="130" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="detailName" label="名称" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="storeCode" label="库房" width="80" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="channel" label="渠道" width="80" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="stockRemain" label="剩余数量" width="70"> </el-table-column>
      <el-table-column prop="stockNumber" label="本次数量" width="110">
        <template #default="scope">
          <el-input v-model="scope.row.stockNumber" autocomplete="off" type="number" style="width: 80px" size="small"
            min="0" :max="scope.row.stockRemain" :disabled="step != 1"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="" width="70" v-show="step == 1">
        <template #default="scope">
          <el-button @click="deleItemEvent(scope.row)" type="primary" link size="small"
            :disabled="id.length > 0">[删除]</el-button>
        </template>
      </el-table-column>
    </el-table-column>
  </el-table>
</template>
<script>
import { fetchBillCode } from "@/api/stock";
import { fetchSkuList } from "@/api/product";
export default {
  name: "assembleDetail",
  components: {},
  data() {
    return {
      companys: [],

      step: 1,
      forms: { storeCode: '半成品库', billType: '组装单' },
      bound: { stockNumber: 1 },
      results: [],
      chooseSku: {},
      chooseBound: {},

      billType: '',
      dict: {
        billTypes: []
      },

      id: ''
    };
  },
  methods: {
    searchSkuAsync(queryString, callback) {
      let search = {
        page: 1,
        rows: 10,
        columns: [
          { prop: "detailCode", searchable: true },
          { prop: "detailName", searchable: true },
          { prop: "detailUnit", searchable: true },
          { prop: "serialCode", searchable: true },
          { prop: "brandCode", searchable: true },
          { prop: "brandName", searchable: true },
          { prop: "brandModelCode", searchable: true },
          { prop: "factoryCode", searchable: true },
          { prop: "factoryName", searchable: true },
          { prop: "factoryModelCode", searchable: true },
        ],
        keyword: this.chooseSku.value,
      };
      fetchSkuList(search).then((res) => {
        console.log(res);
        //res.data.data
        let inputOptions = [];
        res.data.data.forEach(function (item) {
          inputOptions.push({
            value: item.detailName + "[" + item.detailCode + "]" + (item.serialCode ? "[" + item.serialCode + "]" : ''),
            ...item,
          });
        });

        console.log('callback', res);
        callback(inputOptions);
      });
    },

    //选择入库的SKU的选中事件
    chooseSkuHandle(item) {
      this.chooseSku = item;
      this.bound.detailCode = item.detailCode;
      this.bound.detailName = item.detailName;

      this.bound.brandModelCode = item.brandModelCode;
      this.bound.modelCode = item.brandModelCode;
      this.bound.brandCode = item.brandCode;
      this.bound.brandName = item.brandName;
      this.bound.factoryCode = item.factoryCode;
      this.bound.factoryName = item.factoryName;
      this.bound.factoryModelCode = item.factoryModelCode;

      this.bound.unitName = item.detailUnit;

      let billItem = this.dict.billTypes.filter(res => res.name == this.forms.billType)[0];

      this.bound.serialCode = billItem.attribute + this.$util.fmtDate(new Date(), "yyMMddHHmmss");
      this.bound.brandSerialCode = this.bound.certNumber = this.bound.serialCode;

      fetchBillCode({ billType: billItem.value, prev: billItem.attribute }).then((res) => {
        this.forms.billCode = this.bound.billCode = res.data + "-" + this.results.length;
        this.forms.billDate = this.bound.billDate = this.$util.fmtDate(new Date());
      });

      this.step = 3;

      console.log('chooseSkuHandle', this.bound);
    },
    //搜索归属的SPU（请求后台数据）
    searchBoundAsync(queryString, callback) {
      if (!this.forms.companyId) {
        this.$message({ type: "info", message: "请选择货品操作的所属公司" });
        return;
      }

      let search = {
        page: 1,
        rows: 10,
        columns: [
          { prop: "storeCode", searchable: true },
          { prop: "detailCode", searchable: true },
          { prop: "detailName", searchable: true },
          { prop: "serialCode", searchable: true },
          { prop: "channel", searchable: true },
          { prop: "brandCode", searchable: true },
          { prop: "brandName", searchable: true },
          { prop: "brandModelCode", searchable: true },
          { prop: "factoryModelCode", searchable: true },
          { prop: "stockRemain" }, { prop: "useBrandPrice" }, { prop: "noTaxPurchasePrice" },
          { prop: "taxPrice" }, { prop: "purchasePrice" }, { prop: "productPrice" },
          { prop: "labelPrice" }, { prop: "sellPrice" },
        ],
        keyword: this.chooseBound.value,
        filters: []
      };
      search.filters.push({ name: "storeCode", value: this.forms.storeCode, operate: 0, });
      search.filters.push({ name: "companyId", value: this.forms.companyId, operate: 0, });
      if (this.forms.channel && this.forms.channel.length > 0) {
        search.filters.push({ name: "channel", value: this.forms.channel, operate: 0, });
      }
      //在库
      search.filters.push({ name: "stockRemain", value: "0", operate: 1 });
      search.filters.push({ name: "stockStatus", value: "available", operate: 0 });

      this.$kaung.fetch({
        url: '/StockBound/Search',
        method: 'post',
        data: search
      }).then((res) => {
        let inputOptions = [];
        res.data.data.forEach(function (item) {
          inputOptions.push({
            value: (item.storeCode + "[" + item.serialCode + "]") + item.detailName + "[" + item.detailCode + "]在库数" + item.stockRemain,
            ...item,
          });
        });

        callback(inputOptions);
      });
    },
    //选择选中事件
    chooseBoundHandle(item) {
      //this.chooseBound = item;

      if (this.results.filter(res => res.id == item.id).length == 0) {
        item.stockNumber = item.stockRemain;
        if ((this.forms.channel || '').length == 0)
          this.forms.channel = item.channel;

        this.results.push(item);
      } else {
        this.$message({ type: "info", message: "该货品信息已添加请勿重复添加" });
      }
      this.chooseBound = {};
    },

    deleItemEvent(item) {
      if (this.results.length <= 2) {
        this.$message({ type: "info", message: "请选择至少一个以上的加工货品" });
        return;
      }

      this.results = this.results.filter(res => res.id != item.id);

      this.calcColumn();
    },

    calcColumn() {
      //计算合计之后的价格和成本
      this.bound.unitPrice = 0;
      this.bound.useBrandPrice = 0;
      this.bound.noTaxPurchasePrice = 0;
      this.bound.taxPrice = 0;
      this.bound.purchasePrice = 0;
      this.bound.productPrice = 0;
      this.bound.labelPrice = 0;
      this.bound.sellPrice = 0;
      this.bound.weight = 0;
      this.bound.remark = '组装' + this.results.length + '件:';
      this.results.forEach(item => {
        this.bound.unitPrice += (item.unitPrice || 0);
        this.bound.useBrandPrice += (item.useBrandPrice || 0);
        this.bound.noTaxPurchasePrice += (item.noTaxPurchasePrice || 0);
        this.bound.taxPrice += (item.taxPrice || 0);
        this.bound.purchasePrice += (item.purchasePrice || 0);
        this.bound.productPrice += (item.productPrice || 0);
        this.bound.labelPrice += (item.labelPrice || 0);
        this.bound.sellPrice += (item.sellPrice || 0);
        this.bound.weight += (item.weight || 0);
        this.bound.remark += (item.serialCode || '') + ';';
      });
      this.bound.unitPrice = this.bound.unitPrice.toFixed(4);
      this.bound.useBrandPrice = this.bound.useBrandPrice.toFixed(4);
      this.bound.noTaxPurchasePrice = this.bound.noTaxPurchasePrice.toFixed(4);
      this.bound.taxPrice = this.bound.taxPrice.toFixed(4);
      this.bound.purchasePrice = this.bound.purchasePrice.toFixed(4);
      this.bound.productPrice = this.bound.productPrice.toFixed(4);
      this.bound.labelPrice = this.bound.labelPrice.toFixed(4);
      this.bound.sellPrice = this.bound.sellPrice.toFixed(4);
      this.bound.weight = this.bound.weight.toFixed(4);
    },
    confirmEvent() {
      if (this.results.length <= 1) {
        this.$message({ type: "info", message: "请选择至少一个以上的加工货品" });
        return;
      }
      let numberFlat = this.results.filter(item => item.stockNumber <= 0).length;
      if (numberFlat > 0) {
        this.$message({ type: "info", message: "请输入要加工货品的使用数量" });
        return;
      }
      let rangeFlat = this.results.filter(item => item.stockNumber > item.stockRemain).length;
      if (rangeFlat > 0) {
        this.$message({ type: "info", message: "加工货品的使用数量不能超过在库的数量" });
        return;
      }
      this.step = 2;

      this.calcColumn();
    },

    submitEvent() {
      //this.calcColumn();
      if (this.results.length <= 1) {
        this.$message({ type: "info", message: "请选择至少一个以上的加工货品" });
        return;
      }

      this.bound.companyId = this.forms.companyId;
      this.bound.channel = this.forms.channel;
      this.bound.storeCode = "成品库"; //this.forms.storeCode;

      let submitBounds = [this.bound];
      this.results.forEach(q => {
        submitBounds.push({
          ...q, stockNumber: q.stockNumber * -1,
          companyId: this.forms.companyId, channel: this.forms.channel,
          //storeCode: this.forms.storeCode,
        });
      });
      const data = { bill: this.forms, bounds: submitBounds };
      console.log(data);

      let that = this;
      this.$confirm("确认保存并提交该组装单, 是否继续?", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning", })
        .then(() => {
          if (this.id) {
            that.$kaung.fetch({
              url: "/StockBills/SubmitAssemble/" + this.id,
              data: data
            }).then((res) => {
              that.$notify({
                title: (res.code == 200 ? "操作成功" : "操作失败"),
                message: res.content,
                type: res.type,
                duration: 10000
              });
              //this.$alert(res.content, '提示');
              if (res.code == 200) {
                this.$router.push({ path: "assemble", query: {} });
              }
            });
          } else {
            that.$kaung.fetch({
              url: "/StockBills/PrecreateAssemble",
              data: data
            }).then((res) => {
              that.$notify({
                title: (res.code == 200 ? "操作成功" : "操作失败"),
                message: res.content,
                type: res.type,
                duration: 10000
              });
              //this.$alert(res.content, '提示');
              if (res.code == 200) {
                this.$router.push({ path: "assemble", query: {} });
              }
            });
          }
        });
    },
  },
  mounted() {
    this.$kaung.dictionary("stock", "otherbound").then((res) => { this.dict.billTypes = res; });
    this.$kaung.companys().then((res) => {
      this.companys = res;
      this.forms.companyId = this.companys[0].id;
    });
  },
  created() {
    this.id = this.$route.query.id || '';
    if (this.id) {
      this.$kaung.fetch({ url: '/StockBills/DetailAssemble/' + this.id }).then((res) => {
        this.bound = res.data.bounds.filter(res => res.stockNumber > 0)[0];
        this.forms = { ...res.data.bill, ...this.bound };
        this.forms.companyId = this.bound.companyID;
        this.results = res.data.bounds.filter(res => res.stockNumber < 0);
        this.step = 3;

        console.log('created fetch', this.bound, this.forms, this.results);
      });
    }
  },
  watch: {

  },
};
</script>
<style scoped></style>