<template>
  <el-tabs type="border-card" v-model="tabName" @tab-change="fetch">
    <el-tab-pane label="渠道汇总" name="storedata" class="horizbox">
      <el-row type="flex" class="row-bg">
        <el-col :span="4" :xs="24">
          <el-form-item label="关键词" prop="keyword">
            <el-input v-model="paramsObj.keyword" placeholder="支持款式搜索" @keyup.enter="fetch()"
              style="max-width: 120px;"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="5" :xs="24">
          <el-tree-select ref="companys" v-model="paramsObj.companyId" :data="companys" @change="fetch()" clearable
            check-strictly :render-after-expand="false" placeholder="请选择公司" style="width:250px" />
        </el-col>

        <el-col :span="3" :xs="24">
          <el-select v-model="paramsObj.storeCode" placeholder="请选择库房" size="default" @change="fetch()" clearable
            style="max-width: 120px;">
            <el-option v-for="item in store" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-col>
        <el-col :span="3" :xs="24">
          <el-select v-model="paramsObj.channel" placeholder="渠道" size="default" @change="fetch()" clearable
            style="max-width: 120px;">
            <el-option v-for="item in channels" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-col>

        <el-col :span="5" :xs="24">
          <el-date-picker v-model="valueTime" type="daterange" @change="getValueTime(); fetch();" unlink-panels
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="YYYY-MM-DD"
            :shortcuts="$util.getShortCuts()" size="default" style="max-width: 210px;">
          </el-date-picker>
        </el-col>

        <el-col :xs="24" :sm="12" :md="2" :lg="2" :xl="2">
          <el-button style="margin: 0 10px" size="default" type="primary" @click="fetch"> 确认 </el-button>
        </el-col>
      </el-row>

      <el-table class="table" :data="storedata" border size="small" show-summary :summary-method="getSummaries">
        <el-table-column type="index" label="序号" align="center" width="50"> </el-table-column>
        <el-table-column prop="name" label="渠道名称" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="productPrice" label="在库综合成本" width="90" align="right">
          <template #default="row">{{ toBigNumber(row.row.productPrice) }}</template>
        </el-table-column>
        <el-table-column prop="purchasePrice" label="在库采购成本" width="90" align="right">
          <template #default="row">{{ toBigNumber(row.row.purchasePrice) }}</template>
        </el-table-column>
        <el-table-column prop="orderCount" label="订单数" width="60" align="center"></el-table-column>
        <el-table-column prop="orderSellPrice" label="订单金额" width="70" align="right">
          <template #default="row">{{ toBigNumber(row.row.orderSellPrice) }}</template>
        </el-table-column>
        <el-table-column prop="orderProductPrice" label="订单成本" width="70" align="right">
          <template #default="row">{{ toBigNumber(row.row.orderProductPrice) }}</template>
        </el-table-column>

        <el-table-column prop="stockinCount" label="入库数" width="65" align="center"> </el-table-column>
        <!-- <el-table-column prop="stockinPurchasePrice" label="入库采购金额" width="90" align="center"> </el-table-column> -->
        <el-table-column prop="stockinProductPrice" label="入库成本" width="70" align="right">
          <template #default="row">{{ toBigNumber(row.row.stockinProductPrice) }}</template>
        </el-table-column>

        <el-table-column prop="detailChange" label="变动款数" width="65" align="center"> </el-table-column>
        <el-table-column prop="detailTotal" label="总款数" width="60" align="center"> </el-table-column>
        <el-table-column prop="detailRate" label="动销率" width="60" align="center"> </el-table-column>

        <el-table-column prop="daysTotal" label="间隔天数" width="65" align="center"> </el-table-column>
        <el-table-column prop="daysAvgRemain" label="日均库存数" width="80" align="center">
          <template #default="row">{{ (row.row.daysAvgRemain || 0).toFixed(2) }}</template>
        </el-table-column>
        <el-table-column prop="daysAvgProduct" label="日均成本" width="70" align="right">
          <template #default="row">{{ toBigNumber(row.row.daysAvgProduct) }}</template>
        </el-table-column>
        <el-table-column prop="priceRate" label="周转率" width="80" align="center"> </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="渠道报表" name="storechart">
      <el-row type="flex" class="row-bg">
        <el-col :span="4" :xs="24">
          <el-form-item label="关键词" prop="keyword">
            <el-input v-model="paramsObj.keyword" placeholder="支持款式搜索" @keyup.enter="fetch()"
              style="max-width: 120px;"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="5" :xs="24">
          <el-tree-select ref="companys" v-model="paramsObj.companyId" :data="companys" @change="fetch()" clearable
            check-strictly :render-after-expand="false" placeholder="请选择公司" style="width:250px" />
        </el-col>

        <el-col :span="3" :xs="24">
          <el-select v-model="paramsObj.storeCode" placeholder="请选择库房" size="default" @change="fetch()" clearable
            style="max-width: 120px;">
            <el-option v-for="item in store" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-col>
        <el-col :span="3" :xs="24">
          <el-select v-model="paramsObj.channel" placeholder="渠道" size="default" @change="fetch()" clearable
            style="max-width: 120px;">
            <el-option v-for="item in channels" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-col>


        <el-col :xs="24" :sm="12" :md="2" :lg="2" :xl="2">
          <el-button style="margin: 0 10px" size="default" type="primary" @click="fetch"> 确认 </el-button>
        </el-col>
      </el-row>

      <div class="chartbox" id="chartbox" style="width:100%; width: 1400px; height: 1100px; margin:10px auto;"> </div>
    </el-tab-pane>
    <el-tab-pane label="款式报表" name="detaildata">
      <div class="console-page">
        <el-row type="flex" class="row-bg">
          <el-col :span="4" :xs="24">
            <el-form-item label="关键词" prop="keyword">
              <el-input v-model="paramsObj.keyword" placeholder="支持款式搜索" @keyup.enter="fetch()"
                style="max-width: 120px;"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="5" :xs="24">
            <el-tree-select ref="companys" v-model="paramsObj.companyId" :data="companys" @change="fetch()" clearable
              check-strictly :render-after-expand="false" placeholder="请选择公司" style="width:250px" />
          </el-col>

          <el-col :span="3" :xs="24">
            <el-select v-model="paramsObj.storeCode" placeholder="请选择库房" size="default" @change="fetch()" clearable
              style="max-width: 120px;">
              <el-option v-for="item in store" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-col>
          <el-col :span="3" :xs="24">
            <el-select v-model="paramsObj.channel" placeholder="渠道" size="default" @change="fetch()" clearable
              style="max-width: 120px;">
              <el-option v-for="item in channels" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5" :xs="24">
            <el-date-picker v-model="valueTime" type="daterange" @change="getValueTime(); fetch();" unlink-panels
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="YYYY-MM-DD"
              :shortcuts="$util.getShortCuts()" size="default" style="max-width: 210px;">
            </el-date-picker>
          </el-col>

          <el-col :xs="24" :sm="12" :md="4" :lg="4" :xl="4">
            <el-button style="margin: 0 10px" size="default" type="primary" @click="fetch"> 确认 </el-button>
            <el-button type="info" @click="consoleTableExport()">导出明细</el-button>
          </el-col>
        </el-row>

        <el-row :gutter="12" class="panel-group">
          <el-tooltip class="box-item" effect="dark" content="时间周期内的销售总数" placement="top-start">
            <el-col :xs="24" :sm="12" :lg="3" class="card-panel-col">
              <div class="card-panel">
                <div class="card-panel-icon-wrapper icon-people">
                  <el-icon>
                    <Monitor />
                  </el-icon>
                </div>
                <div class="card-panel-description">
                  <div class="card-panel-text">销售件数</div>
                  <div class="card-panel-num"> {{ toBigNumber(blocks.orderPayCount) }} </div>
                </div>
              </div>
            </el-col>
          </el-tooltip>
          <el-tooltip class="box-item" effect="dark" content="时间周期内的销售总额" placement="top-start">
            <el-col :xs="24" :sm="12" :lg="4" class="card-panel-col">
              <div class="card-panel">
                <div class="card-panel-icon-wrapper icon-message">
                  <el-icon>
                    <Stopwatch />
                  </el-icon>
                </div>
                <div class="card-panel-description">
                  <div class="card-panel-text">销售总额(￥)</div>
                  <div class="card-panel-num"> {{ toBigNumber(blocks.orderPayTotal) }} </div>
                </div>
              </div>
            </el-col>
          </el-tooltip>
          <el-tooltip class="box-item" effect="dark" content="时间周期内的销售出库货品对应的货品成品" placement="top-start">
            <el-col :xs="24" :sm="12" :lg="4" class="card-panel-col">
              <div class="card-panel">
                <div class="card-panel-icon-wrapper icon-shopping">
                  <el-icon>
                    <Box />
                  </el-icon>
                </div>
                <div class="card-panel-description">
                  <div class="card-panel-text">销售对应成本(￥)</div>
                  <div class="card-panel-num"> {{ toBigNumber(blocks.orderProductTotal) }} </div>
                </div>
              </div>
            </el-col>
          </el-tooltip>
          <el-tooltip class="box-item" effect="dark" :content="'周期内的采购成本=采购数*(采购价+品牌使用费)'" placement="top-start">
            <el-col :xs="24" :sm="12" :lg="4" class="card-panel-col">
              <div class="card-panel">
                <div class="card-panel-icon-wrapper icon-money">
                  <el-icon>
                    <Files />
                  </el-icon>
                </div>
                <div class="card-panel-description">
                  <div class="card-panel-text">采购总成本(￥)</div>
                  <div class="card-panel-num"> {{ toBigNumber(blocks.purchasePriceTotal) }} </div>
                </div>
              </div>
            </el-col>
          </el-tooltip>
          <el-tooltip class="box-item" effect="dark"
            :content="'SKU动销率=(动销品数' + (blocks.changeCount || '0') + '/总品数' + (blocks.totalCount || '0') + ')*100%  （因算法调整2023年10月以后数据准确）'"
            placement="top-start">
            <el-col :xs="24" :sm="12" :lg="3" class="card-panel-col">
              <div class="card-panel">
                <div class="card-panel-icon-wrapper icon-shopping">
                  <el-icon>
                    <Postcard />
                  </el-icon>
                </div>
                <div class="card-panel-description">
                  <div class="card-panel-text">动销率</div>
                  <div class="card-panel-num"> {{ (blocks.changeRate || '0') + "%" }} </div>
                </div>
              </div>
            </el-col>
          </el-tooltip>
          <el-tooltip class="box-item" effect="dark" :content="'在库价值=在库数*(采购价+品牌使用费)'" placement="top-start">
            <el-col :xs="24" :sm="12" :lg="3" class="card-panel-col">
              <div class="card-panel">
                <div class="card-panel-icon-wrapper icon-money">
                  <el-icon>
                    <Sell />
                  </el-icon>
                </div>
                <div class="card-panel-description">
                  <div class="card-panel-text">在库总值(￥)</div>
                  <div class="card-panel-num"> {{ toBigNumber(blocks.sumProductPrice) }} </div>
                </div>
              </div>
            </el-col>
          </el-tooltip>
          <el-tooltip class="box-item" effect="dark" :content="'销售对应货品采购成本:' + toBigNumber(blocks.orderPurchaseTotal) + '，'
            + '周期天数:' + blocks.rateDays + '，' + '库存日均总值:' + toBigNumber(blocks.avgPurchasePrice) + '，'
            + '周转率=(销售对应的货品采购总成品/库存日均总值)*100% （因算法调整2023年10月以后数据准确）'" placement="top-start">
            <el-col :xs="24" :sm="12" :lg="3" class="card-panel-col">
              <div class="card-panel">
                <div class="card-panel-icon-wrapper icon-shopping">
                  <el-icon>
                    <Sort />
                  </el-icon>
                </div>
                <div class="card-panel-description">
                  <div class="card-panel-text">周转率</div>
                  <div class="card-panel-num">
                    {{ toBigNumber(blocks.ratePriceOfInventor * 100) }} %</div>
                </div>
              </div>
            </el-col>
          </el-tooltip>
        </el-row>

        <el-card class="box-card">
          <el-table :data="tableData" :highlight-current-row="true"
            :default-sort="{ prop: 'saleStockNumber', order: 'descending' }" stripe border height="550"
            style="width: 100%" size="small">
            <el-table-column align="center" label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="detailCode" width="130" align="center" sortable label="系统款号">
              <template #default="dataObj">
                <el-button link style="font-size:0.8em;" @click="setSkuCode(dataObj.row)">{{ dataObj.row.detailCode
                  }}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="serialCode" align="center" sortable label="自定款号" width="110">
              <template #default="dataObj">{{ dataObj.row.serialCode }}</template>
            </el-table-column>
            <el-table-column prop="detailName" align="center" show-overflow-tooltip sortable label="名称">
              <template #default="dataObj">{{ dataObj.row.detailName }}</template>
            </el-table-column>

            <el-table-column prop="detailCode" align="center" label="图片" width="60">
              <template #default="dataObj">
                <!-- <el-image style="height: 30px; width: 30px;" lazy
              :src="apiUrl + 'static/code/' + dataObj.row.detailCode.split('-')[0]"
              :preview-src-list="[apiUrl + 'static/code/' + dataObj.row.detailCode.split('-')[0],]">
              <template #error>
                <div> <img style="width: 100%" src="~@/assets/imgunfine.jpg" alt="" /> </div>
              </template>
          </el-image> -->
                <el-image style="height: 32px; width: 32px" lazy
                  :src="apiUrl + 'static/code/' + dataObj.row.detailCode + '?t=0'"
                  :preview-src-list="[apiUrl + 'static/code/' + dataObj.row.detailCode + '?t=1']">
                  <template #error>
                    <img style="width: 100%" src="~@/assets/imgunfine.jpg" alt="" />
                  </template>
                </el-image>
              </template>
            </el-table-column>

            <el-table-column prop="sumStockRemain" align="center" sortable width="60">
              <template #header>库存数</template>
              <template #default="dataObj">{{ toBigNumber(dataObj.row.sumStockRemain) }}</template>
            </el-table-column>

            <el-table-column prop="sumStockPurchasePrice" align="center" sortable label="库存额" width="85">
              <template #default="dataObj">￥{{ toBigNumber(dataObj.row.sumStockPurchasePrice) }}</template>
            </el-table-column>

            <el-table-column prop="avgStockPurchasePrice" align="center" sortable label="采购均价" width="88">
              <template #default="dataObj">￥{{ toBigNumber(dataObj.row.avgStockPurchasePrice) }}</template>
            </el-table-column>

            <el-table-column align="center" label="日均库存" width="80">
              <template #default="dataObj">
                {{ toBigNumber(dataObj.row.avgHisStockRemain)
                }}</template>
            </el-table-column>

            <!-- <el-table-column prop="AvgHisStockRemain" align="center" label="平均数" width="70">
          <template #default="dataObj">{{ toBigNumber(dataObj.row.startStockNumber / 2 + dataObj.row.endStockNumber
            / 2)
          }}</template>
        </el-table-column> -->

            <el-table-column prop="inStockNumber" align="center" sortable label="采购数" width="70">
              <template #default="dataObj">{{ toBigNumber(dataObj.row.inStockNumber) }}</template>
            </el-table-column>

            <el-table-column prop="sumInPurchasePrice" align="center" sortable width="80">
              <template #header><span title="周期内的整体采购额">采购额</span></template>
              <template #default="dataObj">￥{{ toBigNumber(dataObj.row.sumInPurchasePrice) }}</template>
            </el-table-column>

            <el-table-column prop="saleStockNumber" align="center" sortable label="销售数" width="70">
              <template #default="dataObj">{{ toBigNumber(dataObj.row.saleStockNumber * -1.0) }}</template>
            </el-table-column>

            <el-table-column prop="sumSellPrice" align="center" sortable width="80">
              <template #header><span title="销售额">销售额</span></template>
              <template #default="dataObj">
                <el-tooltip class="box-item" :content="'销售额'" placement="top-start" effect="dark">
                  <div>
                    ￥{{ toBigNumber(dataObj.row.sumSellPrice) }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="sumSellPurchasePrice" align="center" width="85">
              <template #header><span title="销售额对应的采购额">销售采购额</span></template>
              <template #default="dataObj">
                <el-tooltip class="box-item" :content="'销售货品对应的采购额'" placement="top-start" effect="dark">
                  <div>
                    ￥{{ toBigNumber(dataObj.row.sumSellPurchasePrice) }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column prop="stockNumberTurnoverRate" align="center" label="周转率" width="80" sortable>
              <template #default="dataObj">
                <el-tooltip class="box-item" :content="'销售对应货品成本:' + toBigNumber(dataObj.row.sumSellPurchasePrice) + '，'
                  + '日均库存金额:' + toBigNumber(dataObj.row.daysAvgPurchasePrice) + '，'
                  + '周转率=(销售对应货品成本/日均库存金额)*100%'" placement="top-start" effect="dark">
                  {{ (dataObj.row.stockPurchaseTurnoverRate || 0).toFixed(2) }}%
                  <!-- <div v-if="dataObj.row.avgHisStockRemain > 0 && Math.abs(dataObj.row.saleStockNumber) > 0">
                {{ toPercent(Math.abs(dataObj.row.saleStockNumber) / dataObj.row.avgHisStockRemain * 100) }}
              </div>
              <div v-else>
                -
              </div> -->
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </el-tab-pane>
    <el-tab-pane label="退货报表" name="returndata">
      <el-row type="flex" class="row-bg">
        <el-col :span="4" :xs="24">
          <el-form-item label="关键词" prop="keyword">
            <el-input v-model="paramsObj.keyword" placeholder="支持款式搜索" @keyup.enter="fetch()"
              style="max-width: 120px;"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="5" :xs="24">
          <el-tree-select ref="companys" v-model="paramsObj.companyId" :data="companys" @change="fetch()" clearable
            check-strictly :render-after-expand="false" placeholder="请选择公司" style="width:250px" />
        </el-col>

        <el-col :span="3" :xs="24">
          <el-select v-model="paramsObj.storeCode" placeholder="请选择库房" size="default" @change="fetch()" clearable
            style="max-width: 120px;">
            <el-option v-for="item in store" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-col>
        <el-col :span="3" :xs="24">
          <el-select v-model="paramsObj.channel" placeholder="渠道" size="default" @change="fetch()" clearable
            style="max-width: 120px;">
            <el-option v-for="item in channels" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-col>

        <el-col :span="5" :xs="24">
          <el-date-picker v-model="valueTime" type="daterange" @change="getValueTime(); fetch();" unlink-panels
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="YYYY-MM-DD"
            :shortcuts="$util.getShortCuts()" size="default" style="max-width: 210px;">
          </el-date-picker>
        </el-col>


        <el-col :xs="24" :sm="12" :md="2" :lg="2" :xl="2">
          <el-button style="margin: 0 10px" size="default" type="primary" @click="fetch"> 确认 </el-button>
        </el-col>
      </el-row>

      <el-row type="flex" :gutter="20">
        <el-col :span="24" :xs="24">
          <h4 style="margin:10px auto; text-align: left;">周期内渠道的退货率汇总情况</h4>
          <el-table class="table" :data="returnChannelData" border size="small" style="margin:0 auto">
            <el-table-column type="index" label="序号" align="center" width="50"> </el-table-column>
            <el-table-column prop="渠道名称" label="渠道名称" width="130" sortable show-overflow-tooltip> </el-table-column>
            <el-table-column prop="销售数量" label="销售数量" width="120" sortable align="center"> </el-table-column>
            <el-table-column prop="退货数量" label="退货数量" width="120" sortable align="center"> </el-table-column>
            <el-table-column prop="退货率" label="退货率(%)" width="100" sortable align="center"> </el-table-column>
          </el-table>

          <h4 style="margin:10px auto; text-align: left;">周期内渠道的款式退货率汇总Top100</h4>
          <el-table class="table" :data="returnProductData" border size="small">
            <el-table-column type="index" label="序号" align="center" width="50"> </el-table-column>
            <el-table-column prop="系统款号" label="系统款号" width="130" sortable show-overflow-tooltip> </el-table-column>
            <el-table-column prop="款式名称" label="款式名称" width="180" sortable show-overflow-tooltip> </el-table-column>
            <el-table-column prop="大类" label="大类" width="100" sortable show-overflow-tooltip> </el-table-column>
            <el-table-column prop="自定款号" label="自定款号" width="120" sortable show-overflow-tooltip> </el-table-column>
            <el-table-column prop="规格" label="规格" width="80" sortable show-overflow-tooltip> </el-table-column>
            <el-table-column prop="主材质" label="主材质" width="80" sortable show-overflow-tooltip> </el-table-column>
            <el-table-column prop="克重" label="克重" width="80" sortable show-overflow-tooltip> </el-table-column>
            <el-table-column prop="品牌款号" label="品牌款号" width="120" sortable show-overflow-tooltip> </el-table-column>
            <el-table-column prop="工厂款号" label="工厂款号" width="120" sortable show-overflow-tooltip> </el-table-column>

            <el-table-column prop="销售数量" label="销售数量" width="100" sortable align="center"> </el-table-column>
            <el-table-column prop="退货数量" label="退货数量" width="100" sortable align="center"> </el-table-column>
            <el-table-column prop="退货率" label="退货率(%)" width="100" sortable align="center"> </el-table-column>
          </el-table>
        </el-col>
      </el-row>


    </el-tab-pane>
  </el-tabs>

  <!-- SKU详情 -->
  <div class="xlsdialog">
    <el-dialog title="SKU详情" v-model="skuDetailDialogVisible" :show-close="true" :close-on-click-modal="false"
      destroy-on-close top="5vh" @closed="skuDetailDialogVisible = false" width="60%" center>
      <skudetail :detailCode="detailCode"></skudetail>
    </el-dialog>
  </div>

  <div class="dialog">
    <resetPwdDialog :visible="resetDialogVisible" title="密码过于简单，请重置默认密码"></resetPwdDialog>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { consoleBlocks, consoleTable, getRangeTime, consoleExport } from "@/api/console";
import skudetail from "../stock/product/skudetail.vue";
import resetPwdDialog from "@/views/sys/user/reset.vue";
import request from '@/utils/request'

export default {
  name: "console",
  components: { skudetail, resetPwdDialog },
  data() {
    return {
      tabName: "storedata",

      apiUrl: "",
      //数据版
      blocks: {},
      //仓库
      store: [],
      value: "全部",
      //部门
      channels: [],
      brands: [],
      value2: "全部",

      //时间范围
      valueTime: [],
      //表单params
      paramsObj: {
        keyword: '',
        companyId: '',
        storeCode: "",
        channel: "",
        brand: '',
        startDate: "",
        endDate: "",
      },
      //回传数据
      tableData: [],
      //周转率
      tockNumberTurnoverRateTotalValue: "0",

      skuDetailDialogVisible: false,
      detailCode: '',

      resetDialogVisible: false,
      companys: [],

      storedata: [],
      returnChannelData: [],
      returnProductData: [],
    };
  },
  methods: {
    fetch() {
      //this.paramsObj.endDate += ' 23:59:59';
      if (this.tabName == 'detaildata') {
        this.consoleBlockEvent(this.paramsObj);
        this.consoleTableEvent(this.paramsObj);
      }
      else if (this.tabName == 'storechart')
        this.getStoreTable();
      else if (this.tabName == 'storedata') {
        request({
          url: '/StockStore/ChannelReport',
          method: 'get',
          params: this.paramsObj
        }).then((res) => {
          this.storedata = res.data;
        });
      }
      else if (this.tabName == 'returndata') {
        request({
          url: '/StockStore/ReturnReport',
          method: 'get',
          params: this.paramsObj
        }).then((res) => {
          this.returnChannelData = res.data.channelData;
          this.returnProductData = res.data.productData;
        });
      }
    },
    //获取概览数据
    consoleBlockEvent(params) {
      consoleBlocks(params).then((res) => {
        this.blocks = { ...res.data };
        console.log(this.blocks);
      });
    },
    //获取表格数据与周转率
    consoleTableEvent(params) {
      consoleTable(params).then((res) => {
        this.tableData = Object.values({ ...res.data });
      });
    },
    consoleTableExport() {
      consoleExport(this.paramsObj).then((res) => {
        this.$notify({
          title: "导出成功",
          message: res.content,
          type: "success",
          duration: 10000
        });
        window.location.href = process.env.VUE_APP_BASEURL + res.data;
      });
    },
    //表单获取值
    formatTime(val) {
      return (
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
      );
    },
    getValueTime() {
      this.paramsObj.startDate = this.formatTime(this.valueTime[0]);
      this.paramsObj.endDate = this.formatTime(this.valueTime[1]);
    },
    //获取年度year/季度quarter/月度month/周week时间范围(例:rangeTime('month'))
    rangeTime(val) {
      this.paramsObj.startDate = getRangeTime(val)[0];
      this.paramsObj.endDate = getRangeTime(val)[1];
      return [getRangeTime(val)[0], getRangeTime(val)[1]];
    },
    //获取数据换算单位，大于万转换，null值为0
    toBigNumber(val) {
      if (val == null) { return '-'; }
      else if (Math.round(val) === val && val / 10000 < 1) { return val; }
      else { return this.toNumberFixed(val.toFixed(2)); }
    },
    toNumberFixed(value) {
      const newValue = ['', '', '']
      let fr = 1000
      let num = 3
      let text1 = ''
      let fm = 1
      while (value / fr >= 1) {
        fr *= 10
        num += 1
      }
      if (num <= 4) { // 千
        newValue[0] = value
      } else if (num <= 8) { // 万
        text1 = parseInt(num - 4) / 3 > 1 ? '千万' : '万'
        // tslint:disable-next-line:no-shadowed-variable
        fm = text1 === '万' ? 10000 : 10000000
        if (value % fm === 0) {
          newValue[0] = parseInt(value / fm) + ''
        } else {
          newValue[0] = parseFloat(value / fm).toFixed(2) + ''
        }
        newValue[1] = text1
      } else if (num <= 16) { // 亿
        text1 = (num - 8) / 3 > 1 ? '千亿' : '亿'
        text1 = (num - 8) / 4 > 1 ? '万亿' : text1
        text1 = (num - 8) / 7 > 1 ? '千万亿' : text1
        // tslint:disable-next-line:no-shadowed-variable
        fm = 1
        if (text1 === '亿') {
          fm = 100000000
        } else if (text1 === '千亿') {
          fm = 100000000000
        } else if (text1 === '万亿') {
          fm = 1000000000000
        } else if (text1 === '千万亿') {
          fm = 1000000000000000
        }
        if (value % fm === 0) {
          newValue[0] = parseInt(value / fm) + ''
        } else {
          newValue[0] = parseFloat(value / fm).toFixed(2) + ''
        }
        newValue[1] = text1
      }
      if (value < 1000) {
        newValue[0] = value + ''
        newValue[1] = ''
      }
      return newValue.join('')
    },
    //百分位单位换算
    toPercent(val) {
      if (val == null) { return 0 + "%"; }
      else if (Math.round(val) === val && val / 10000 < 1) { return val + "%"; }
      else { return val.toFixed(2) + "%"; }
    },

    setSkuCode(row) {
      this.skuDetailDialogVisible = true;
      this.detailCode = row.detailCode;
    },

    getStoreTable() {
      var chartDom = document.getElementById('chartbox');
      var myChart = echarts.init(chartDom);

      request({
        url: '/StockStore/StoreTable/',
        method: 'get',
        params: this.paramsObj
      }).then((res) => {
        console.log(res);

        // let resData = [];
        // res.data.forEach(function (item) {
        //   resData.push({
        //     value: item.realName,
        //     ...item,
        //   });
        // });

        // var datas = [
        //   ////////////////////////////////////////
        //   [
        //     { name: '圣彼得堡来客', value: 5.6 },
        //     { name: '陀思妥耶夫斯基全集', value: 1 },
        //     { name: '史记精注全译（全6册）', value: 0.8 },
        //     { name: '加德纳艺术通史', value: 0.5 },
        //     { name: '表象与本质', value: 0.5 },
        //     { name: '其它', value: 3.8 }
        //   ],
        //   // ////////////////////////////////////////
        //   [
        //     { name: '银河帝国5：迈向基地', value: 3.8 },
        //     { name: '俞军产品方法论', value: 2.3 },
        //     { name: '艺术的逃难', value: 2.2 },
        //     { name: '第一次世界大战回忆录（全五卷）', value: 1.3 },
        //     { name: 'Scrum 精髓', value: 1.2 },
        //     { name: '其它', value: 5.7 }
        //   ],
        //   ////////////////////////////////////////
        //   [
        //     { name: '克莱因壶', value: 3.5 },
        //     { name: '投资最重要的事', value: 2.8 },
        //     { name: '简读中国史', value: 1.7 },
        //     { name: '你当像鸟飞往你的山', value: 1.4 },
        //     { name: '表象与本质', value: 0.5 },
        //     { name: '其它', value: 3.8 }
        //   ]
        // ];

        let datas = res.data;
        let option = {
          title: [
            {
              text: '在库货品库房占比分布',
              top: '10px',
              left: '10%',
              textStyle: {
                color: '#000',
                fontWeight: 'normal',
                fontSize: 18
              }
            },
            {
              text: '在库货品渠道占比分布',
              top: '10px',
              left: '50%',
              textStyle: {
                color: '#000',
                fontWeight: 'normal',
                fontSize: 18
              }
            },

            {
              text: '在库货品总价值占比TOP10',
              top: '360px',
              left: '10%',
              textStyle: {
                color: '#000',
                fontWeight: 'normal',
                fontSize: 18
              }
            },
            {
              text: '在库货品总数量占比TOP10',
              top: '360px',
              left: '50%',
              textStyle: {
                color: '#000',
                fontWeight: 'normal',
                fontSize: 18
              }
            },

            {
              text: '近一个月销售金额排名TOP10',
              top: '700px',
              left: '10%',
              textStyle: {
                color: '#000',
                fontWeight: 'normal',
                fontSize: 18
              }
            },
            {
              text: '近一个月畅销数量排名TOP10',
              top: '700px',
              left: '50%',
              textStyle: {
                color: '#000',
                fontWeight: 'normal',
                fontSize: 18
              }
            }
          ],
          series: datas.map(function (data, idx) {
            var top = parseInt(idx / 2) * 33;
            return {
              type: 'pie',
              radius: [20, 60],
              top: top + '%',
              height: '300px',
              left: ((idx % 2 * 40) + 10) + '%',
              width: 400,
              itemStyle: {
                borderColor: '#fff',
                borderWidth: 1
              },
              label: {
                alignTo: 'edge',
                formatter: '{name|{b}}\n{time|{c} 元(件)}',
                minMargin: 5,
                edgeDistance: 10,
                lineHeight: 15,
                rich: {
                  time: {
                    fontSize: 10,
                    color: '#999'
                  }
                }
              },
              labelLine: {
                length: 15,
                length2: 0,
                maxSurfaceAngle: 80
              },
              labelLayout: function (params) {
                const isLeft = params.labelRect.x < myChart.getWidth() / 2;
                const points = params.labelLinePoints;
                // Update the end point.
                points[2][0] = isLeft
                  ? params.labelRect.x
                  : params.labelRect.x + params.labelRect.width;
                return {
                  labelLinePoints: points
                };
              },
              data: data
            };
          })
        };
        myChart.setOption(option);
      });
    },

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index >= 9) return;

        if (index === 0) {//只找第一列放合计
          sums[index] = '合计';
          return;
        }

        //把对应一列中的之全部取出，放到一个数组中
        const values = data.map(item => Number(item[column.property]));
        //如果el-table-column中没有prop属性，这里的column.property是undefined，所以判断一下values数组是否为空
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            //console.log(prev, curr);
            //将values中的每个值转换为number类型
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          // if (index == 12) {
          //   sums[index] = '';
          // }
          sums[index] = this.$util.fmtMoney(sums[index] || '');
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
  },
  created() {
    this.$kaung.companys().then((res) => {
      this.companys = res;
      //this.paramsObj.companyId = res[0].id;

      this.valueTime = this.rangeTime("month");

      this.fetch();
    });

    this.apiUrl = process.env.VUE_APP_BASEURL;

    //this.forms.account = this.$store.getters.getUserInfo.name;
  },
  mounted() {
    //console.log(this.$route.query.type)
    if (this.$route.query.type == 'simple') {
      this.$message.error("密码过于简单，请重置默认密码");
      //this.$emit('openPwd');
      this.resetDialogVisible = true;
    }

    this.$kaung.dictionary("stock", "store").then(res => this.store = res);
    this.$kaung.dictionary("stock", "channel").then(res => this.channels = res);
    this.$kaung.dictionary("stock", "brand").then(res => this.brands = res);
  }
};
</script>

<style lang="less">
.console-page {
  .el-row {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .box-card {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h4 {
    margin-top: 5px;

    .number {
      color: #409eff;
    }
  }

  .tableshow {
    img {
      transform: scale(1.3, 1.3);
    }
  }

  .showtext {
    line-height: 20px;
    margin-left: 10px;
  }

  .row-bg {
    .el-col {
      padding: 2px 0;
    }

    padding: 10px 10px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  .el-date-editor {
    width: 100%;
    max-width: 280px;
  }

  .text-gray {
    font-family: "PingFang SC";
  }

  .el-table th>.cell {
    padding: 0;
    color: #000;
  }

  .el-table .caret-wrapper {
    width: 12px;
    left: -5px;
  }
}

.panel-group .card-panel:hover {
  background: #a9a9a9;
}

.panel-group .card-panel {
  height: 70px !important;
}

.panel-group .card-panel .card-panel-icon-wrapper {
  margin: 10px 0 0 10px !important;
}

.card-panel .card-panel-description {
  margin-top: 10px !important;
}
</style>

<style lang="scss" scoped>
.panel-group {
  margin-top: 0px;

  .card-panel-col {
    margin-bottom: 0px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.05);

    &:hover {
      background: #f8f8f8;

      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3;
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3;
    }

    .card-panel-icon-wrapper {
      float: left;
      text-align: center;
      font-size: 3em;
      margin: 23px 5px;
      // padding: 5px 0;
      transition: all 0.38s ease-out;
      border-radius: 6px;
      width: 25%;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px 0px 0 0;
      width: 60%;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        // font-size: 1.2em;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 1.2em;
      }
    }
  }
}

@media (max-width: 550px) {
  // .card-panel-description {
  //   display: none;
  // }

  .card-panel-icon-wrapper {
    // float: none !important;
    width: 100%;
    // height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
</style>
