<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>操作日志</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="query" ref="query" label-width="70px" class="search-form" @submit.prevent>
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="query.keyword" @keyup.enter="fetchs" prefix-icon="Search"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="时间范围" label-width="90px">
          <el-col>
            <el-date-picker v-model="dateRange" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"
              unlink-panels :shortcuts="$util.getShortCuts()" @change="fetchs()" style="max-width:210px;">
            </el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <basic-table ref="table" class="table" :query="query" @handler="fetchs">
    </basic-table>

  </el-card>

</template>

<script>
import StaticDialog from "@/components/static-dialog.vue";
import BasicTable from "@/components/basic-table.vue";
export default {
  name: "SysOperateLog",
  components: { StaticDialog, BasicTable },
  data() {
    return {
      query: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },
          { prop: "id", label: "ID", sortable: true, searchable: true, width: "200", showOverflowTooltip: true },
          { prop: "logType", label: "日志类型", width: "80", showOverflowTooltip: true },
          { prop: "logTitle", label: "标题", width: "120", showOverflowTooltip: true },
          { prop: "logDate", label: "时间", width: "180", searchable: true, showOverflowTooltip: true },
          { prop: "action", label: "操作类型", width: "120", searchable: true, showOverflowTooltip: true },
          { prop: "keyValue", label: "关键字", width: "180", searchable: true, showOverflowTooltip: true },
          { prop: "description", label: "描述", width: "200", searchable: true, showOverflowTooltip: true },
          { prop: "userName", label: "操作人", width: "90", searchable: true, },
          { prop: "createDate", label: "日志时间", width: "180" },
        ],
        filters: [],
        sidx: "id",
        sord: "desc"
      },
      dateRange: [],
    };
  },
  methods: {
    fetchs() {
      this.query.filters = [];
      if (this.dateRange && this.dateRange[0])
        this.query.filters.push({
          name: "createDate",
          value: this.$util.fmtDate(this.dateRange[0], "yyyy-MM-dd"),
          operate: 2,
        });
      if (this.dateRange && this.dateRange[1])
        this.query.filters.push({
          name: "createDate",
          value: this.$util.fmtDate(this.dateRange[1], "yyyy-MM-dd") + " 23:59:59",
          operate: 3,
        });

      let { data, records, total, ...params } = { ...this.query };
      this.$kaung.search(params).then((res) => {
        this.query = { ...res.data };
      });
    }
  },
  created: function () {
  },
  mounted() {
    this.dateRange = this.$util.getCurrentDate('today');

    //kaung插件初始化
    this.$kaung.init(this.$options.name);
    this.fetchs();
  },
};
</script>