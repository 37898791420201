<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card>
          <el-row>
            <el-col :span="4">
              <a href="/workflow/flowHistory">
                <el-statistic title="待办记录数" :value="result.todoCount" />
              </a>
            </el-col>
            <el-col :span="4">
              <a href="/order/preInfo">
                <el-statistic title="今日总订单数(本部门)" :value="result.orderTotalCount||0" />
              </a>
            </el-col>
            <el-col :span="4">
              <a href="/order/preInfo0">
                <el-statistic title="今日待确认订单(本部门)" :value="result.orderNewCount||0" />
              </a>
            </el-col>
            <el-col :span="4">
              <a href="/order/preInfo">
                <el-statistic title="今日销售额(本部门)" :value="result.orderTotalAmount||0" />
              </a>
            </el-col>
            <el-col :span="4">
              <a href="/order/returnInfo">
                <el-statistic title="今日售后订单(本部门)" :value="result.returnTotalCount||0" />
              </a>
            </el-col>
            <el-col :span="4">
              <a href="/order/returnInfo">
                <el-statistic title="今日售后金额(本部门)" :value="result.returnTotalAmount||0" />
              </a>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top:12px;">
      <el-col :span="12">
        <el-card>
          <div slot="header" class="clearfix">
            <h5>我的审批单据</h5>
          </div>
          <div>
            <el-timeline style="text-align: left;">
              <el-timeline-item v-for="item in todoList" :key="item.id" :timestamp="item.createDate" placement="top"
                style="padding-bottom: 10px;">
                【<a :href="item.formUrl">{{ item.billCode }}</a>】
                <!-- <span v-if="item.status == 0">草稿</span>
                <span v-else-if="item.status == 10">审核中</span>
                <span v-else-if="item.status == 50">审批完成</span>
                <span v-else-if="item.status == -10">审批退回</span>
                <span v-else-if="item.status == -50">审批驳回</span>
                <span v-else></span> -->

                <p style="display:inline; font-weight: bold;">
                  {{ item.flowName || item.formName }}
                </p>
                <p style="display:inline; margin-left: 15px; font-size:small;">
                  {{ item.formDesc }}
                </p>
                <p style="display:inline;">
                  {{ item.nodeName }}
                </p>
                <p style="display: inline;  float: right; color: gray;">
                  <!-- {{ item.departmentName }} - -->
                  {{ item.createUserName }}
                </p>
                <!-- <el-button type="primary" @click="handleProcess(item)">处理</el-button> -->
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div slot="header" class="clearfix">
            <h5>最新订单列表</h5>
          </div>
          <div>
            <el-timeline style="text-align: left;">
              <el-timeline-item v-for="item in orderList" :key="item.id" :timestamp="item.platformOrderDate" placement="top" style="padding-bottom: 10px;">
                {{ item.platformName }}【<a href="/order/preInfo">{{ item.platformOrderNo }}</a>】

                <p style="display:inline;">
                  金额：{{item.itemTradeFee}}，部门：{{ item.departmentName }}
                </p>
                <p style="display:inline;margin-left: 15px;">
                  {{ item.itemSpec }}
                </p>
                <p style="display: inline;  float: right; color: gray;">
                  <!-- {{ item.departmentName }} - -->
                  {{ item.orderFrom }}
                </p>
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
  <!-- <example class="example" />
  <hr />
  <static-dialog class="dialog" title="编辑" :visible="false">
    <div>1234</div>
  </static-dialog> -->

  <div class="dialog">
    <resetPwdDialog :visible="resetDialogVisible" title="密码过于简单，需要重置该默认密码"></resetPwdDialog>
  </div>

</template>
<script>
import resetPwdDialog from "@/views/sys/user/reset.vue";
//import Example from "@/components/example.vue";
//import StaticDialog from "@/components/static-dialog.vue";
export default {
  name: "default",
  //components: {Example,StaticDialog,},
  components: { resetPwdDialog },
  data() {
    return {
      resetDialogVisible: false,
      result: {},
      todoList: [],
      orderList: [],
    };
  },
  methods: {},
  mounted() {
    this.$kaung.fetch({ url: '/Console/UserCenter', method: 'get' }).then((res) => {
      this.result = res.data.report;
      this.todoList = res.data.todoList;
      this.orderList = res.data.orderList;
    });
  },
  created() {
    //console.log(this.$route.query.type)
    if (this.$route.query.type == 'simple') {
      this.$message.error("密码过于简单，请重置默认密码");
      //this.$emit('openPwd');
      this.resetDialogVisible = true;
    }
  },
};
</script>
<style scoped>
.el-col {
  text-align: center;
}
</style>