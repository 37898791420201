import { createRouter, createWebHistory } from 'vue-router'

import Layout from '@/layout/'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index'),
    meta: { requireAuth: false }
  },
  {
    path: '/oauth2',
    name: 'oauth2',
    component: () => import('@/views/login/oauth2'),
    meta: { requireAuth: false }
  },
  {
    path: '/preview',
    name: 'preview',
    component: () => import('@/views/home/preview')
  },
  {
    path: '/',
    //name: 'layout',
    component: Layout,
    children: [{
      path: '/',
      name: 'Default',
      component: () => import('@/views/home/default')
    },
    {
      path: '/console',
      name: 'Console',
      component: () => import('@/views/home/console')
    },
    {
      path: '/home',
      name: 'Home',
      component: () => import('@/views/home/index')
    },
    {
      path: '/version',
      name: 'version',
      component: () => import('@/views/home/version')
    }
    ]
  },
  {
    path: '/sys/',
    component: Layout,
    children: [{
      path: 'menu',
      name: 'Menu',
      component: () => import('@/views/sys/menu/index')
    },
    {
      path: 'role',
      name: 'Role',
      component: () => import('@/views/sys/role/index')
    },
    {
      path: 'user',
      name: 'User',
      component: () => import('@/views/sys/user/index')
    },
    {
      path: 'userReset',
      name: 'UserReset',
      component: () => import('@/views/sys/user/reset')
    },
    {
      path: 'dictionary',
      name: 'Dictionary',
      component: () => import('@/views/sys/dictionary/index')
    },
    {
      path: 'company',
      name: 'Company',
      component: () => import('@/views/sys/company/index')
    },
    {
      path: 'department',
      name: 'Department',
      component: () => import('@/views/sys/department/index')
    }
    ]
  },
  {
    path: '/stock/',
    component: Layout,
    children: [{
      path: 'product/spulist',
      name: 'SpuList',
      component: () => import('@/views/stock/product/spulist')
    },
    {
      path: 'product/skulist',
      name: 'SkuList',
      component: () => import('@/views/stock/product/skulist')
    },
    // {
    //   path: 'product/skudetail',
    //   name: 'SkuDetail',
    //   component: () => import('@/views/stock/product/skudetail')
    // },
    {
      path: 'bills/index',
      name: 'BillIndex',
      component: () => import('@/views/stock/bills/index')
    },
    {
      path: 'bills/inlist',
      name: 'billInList',
      component: () => import('@/views/stock/bills/inlist')
    },
    {
      path: 'bills/indetail',
      name: 'billInDetail',
      component: () => import('@/views/stock/bills/indetail')
    },
    {
      path: 'bills/detail',
      name: 'BillDetail',
      component: () => import('@/views/stock/bills/detail')
    },
    {
      path: 'bills/instore',
      name: 'Instore',
      component: () => import('@/views/stock/bills/instore')
    },
    {
      path: 'bills/outstore',
      name: 'Outstore',
      component: () => import('@/views/stock/bills/outstore')
    },
    {
      path: 'bills/assemble',
      name: 'Assemble',
      component: () => import('@/views/stock/bills/assemble')
    },
    {
      path: 'bills/assembleDetail',
      name: 'AssembleDetail',
      component: () => import('@/views/stock/bills/assembleDetail')
    },
    {
      path: 'check/index',
      name: 'CheckIndex',
      component: () => import('@/views/stock/check/index')
    },
    {
      path: 'check/detail',
      name: 'CheckDetail',
      component: () => import('@/views/stock/check/detail')
    },
    {
      path: 'check/stockCheck',
      name: 'StockCheck',
      component: () => import('@/views/stock/check/stockCheck')
    },
    {
      path: 'store/index',
      name: 'StoreIndex',
      component: () => import('@/views/stock/store/index')
    },
    {
      path: 'bound/index',
      name: 'BoundIndex',
      component: () => import('@/views/stock/bound/index')
    },
    {
      path: 'bound/detail',
      name: 'BoundDetail',
      component: () => import('@/views/stock/bound/detail')
    },
    {
      path: 'outOrder/index',
      name: 'outOrderIndex',
      component: () => import('@/views/stock/outOrder/index')
    },
    {
      path: 'outOrder/detail',
      name: 'outOrderDetail',
      component: () => import('@/views/stock/outOrder/detail')
    },
    {
      path: 'taxticket/index',
      name: 'taxticket',
      component: () => import('@/views/stock/taxticket/index')
    },
    {
      path: 'report/sellreport',
      name: 'sellreport',
      component: () => import('@/views/stock/report/sellreport')
    },
    ]
  },

  {
    path: '/workflow/',
    component: Layout,
    children: [{
      path: 'instance',
      name: 'instance',
      component: () => import('@/views/workflow/instance')
    }, {
      path: 'application',
      name: 'application',
      component: () => import('@/views/workflow/application')
    }, {
      path: 'configer',
      name: 'configer',
      component: () => import('@/views/workflow/flowConfiger')
    },
    {
      path: 'flowdraw',
      name: 'flowdraw',
      component: () => import('@/views/workflow/flowDraw')
    },
    {
      path: 'flowForm',
      name: 'flowForm',
      component: () => import('@/views/workflow/flowForm')
    },
    {
      path: 'flowHistory',
      name: 'flowHistory',
      component: () => import('@/views/workflow/flowHistory')
    }
    ]
  },

  {
    path: '/mail/',
    component: Layout,
    children: [
      {
        path: 'scale/index',
        name: 'ScaleIndex',
        component: () => import('@/views/mail/scale/index')
      },
      {
        path: 'scale/scale',
        name: 'scaleDetail',
        component: () => import('@/views/mail/scale/scale')
      },
      {
        path: 'bill/index',
        name: 'billIndex',
        component: () => import('@/views/mail/bill/index')
      },
      {
        path: 'bill/detail',
        name: 'billDetail',
        component: () => import('@/views/mail/bill/detail')
      },
      {
        path: 'other/index',
        name: 'otherIndex',
        component: () => import('@/views/mail/other/index')
      },
      {
        path: 'other/detail',
        name: 'otherDetail',
        component: () => import('@/views/mail/other/detail')
      },
      {
        path: 'union/index',
        name: 'UnionIndex',
        component: () => import('@/views/mail/union/index')
      },
      {
        path: 'union/detail',
        name: 'UnionDetail',
        component: () => import('@/views/mail/union/detail')
      },
      {
        path: 'partner/index',
        name: 'PartnerIndex',
        component: () => import('@/views/mail/partner/index')
      },
      {
        path: 'partner/detail',
        name: 'PartnerDetail',
        component: () => import('@/views/mail/partner/detail')
      }
    ]
  },

  {
    path: '/order/',
    component: Layout,
    children: [{
      path: 'preInfo',
      name: 'preInfo',
      component: () => import('@/views/order/preInfo/index')
    },
    {
      path: 'preInfo0',
      name: 'preInfo0',
      component: () => import('@/views/order/preInfo/index?status=0'),
      meta: { status: 0 }
    },
    {
      path: 'preInfo10',
      name: 'preInfo10',
      component: () => import('@/views/order/preInfo/index?status=10'),
      meta: { status: 10 }
    },
    {
      path: 'preInfo20',
      name: 'preInfo20',
      component: () => import('@/views/order/preInfo/index?status=20'),
      meta: { status: 20 }
    },
    {
      path: 'preInfo30',
      name: 'preInfo30',
      component: () => import('@/views/order/preInfo/index?status=30'),
      meta: { status: 30 }
    },
    {
      path: 'upload',
      name: 'upload',
      component: () => import('@/views/order/preInfo/upload')
    },
    {
      path: 'detail',
      name: 'orderDetail',
      component: () => import('@/views/order/preInfo/detail')
    },
    {
      path: 'printInfo',
      name: 'printInfo',
      component: () => import('@/views/order/printInfo/index')
    },
    {
      path: 'printPdd',
      name: 'printPdd',
      component: () => import('@/views/order/printInfo/pddprint')
    },
    {
      path: 'printHistory',
      name: 'printHistory',
      component: () => import('@/views/order/printInfo/history')
    },
    {
      path: 'returnInfo',
      name: 'orderReturn',
      component: () => import('@/views/order/return/index')
    },
    {
      path: 'returnInfo10',
      name: 'returnInfo10',
      component: () => import('@/views/order/return/index?status=10'),
      meta: { status: 10 }
    },
    {
      path: 'returnInfo50',
      name: 'returnInfo50',
      component: () => import('@/views/order/return/index?status=50'),
      meta: { status: 50 }
    },
    {
      path: 'returnInfo200',
      name: 'returnInfo200',
      component: () => import('@/views/order/return/index?status=200'),
      meta: { status: 200 }
    },
    {
      path: 'addReturn',
      name: 'addReturn',
      component: () => import('@/views/order/return/addreturn')
    },]
  },

  {
    path: '/diamond/',
    component: Layout,
    children: [{
      path: 'stock/index',
      name: 'stockIndex',
      component: () => import('@/views/diamond/stock/index')
    }
    ]
  },

  {
    path: '/logs/',
    component: Layout,
    children: [{
      path: 'apilogs',
      name: 'apiLogs',
      component: () => import('@/views/sys/logs/apilogs')
    },{
      path: 'operatelog',
      name: 'operatelog',
      component: () => import('@/views/sys/logs/operatelog')
    }]
  },

  {
    path: '/hrdata/',
    component: Layout,
    children: [{
      path: 'signlog',
      name: 'signlog',
      component: () => import('@/views/hrdata/signlog')
    }, {
      path: 'employee',
      name: 'employee',
      component: () => import('@/views/hrdata/employee')
    }, {
      path: 'salary',
      name: 'salary',
      component: () => import('@/views/hrdata/salary')
    }]
  },

  {
    path: '/fund/',
    component: Layout,
    children: [{
      path: 'fundflowlog',
      name: 'fundflowlog',
      component: () => import('@/views/fund/fundflowlog')
    }, {
      path: 'fundreport',
      name: 'fundreport',
      component: () => import('@/views/fund/fundreport')
    }, {
      path: 'ordersell',
      name: 'ordersell',
      component: () => import('@/views/fund/ordersell')
    }, {
      path: 'fundbilllog',
      name: 'fundbilllog',
      component: () => import('@/views/fund/fundbilllog')
    }, {
      path: 'calculator',
      name: 'calculator',
      component: () => import('@/views/fund/calculator')
    }]
  },

  {
    path: '/demo/',
    component: Layout,
    children: [{
      path: 'signalr',
      name: 'SignalR',
      component: () => import('@/views/demo/signalr')
    }, {
      path: 'flowchart',
      name: 'Flowchart',
      component: () => import('@/views/demo/flowchart')
    },]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), routes
})

//路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }

  //判断是否为true，如果为true就是要登录，去判断token是否存在。存在就执行，不存在就跳转登录页面
  if (to.meta.requireAuth == undefined || to.meta.requireAuth) {
    if (sessionStorage.getItem('access_token')) {
      next() //允许通过
    } else {
      next('/login?redirectUrl=' + encodeURIComponent(location.pathname + location.search))
    }
  } else {
    next()
  }
})

export default router