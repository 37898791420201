<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">进销存</el-breadcrumb-item>
        <el-breadcrumb-item>操作入库</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <div v-if="!showDetails" class="storedialog load-dialog">
      <el-button link @click="showDetails = true" class="download" style="margin:0 auto;">
        方式1：手动填写入库的货品信息操作入库
      </el-button>
      <hr>

      <span class="download">
        <el-upload v-loading="loading" class="upload-box" ref="upload" action="#" :on-change="handleChange"
          :on-remove="handleRemove" :on-exceed="handleExceed" :file-list="fileList" :http-request="submitUpload"
          :auto-upload="true" :limit="1" style="display:inline-flex;margin-right: 0px;">
          方式2：上传表格入库
        </el-upload>，
        <a target="_blank" :href="templateUrl">下载入库模板.xlsx </a>
      </span>

      <div class="errtext" v-for="(value, key) in resList" :key="key">
        <el-icon>
          <CloseBold />
        </el-icon>
        {{ "第" + key + " " + value }}
      </div>
      <!-- <el-upload ref="upload" action :show-file-list="false" :multiple="false" :auto-upload="true" :on-change="handleChange"
      :on-remove="handleRemove" :on-exceed="handleExceed" :http-request="submitUpload"  :limit="1"
      style="display:inline-flex;vertical-align:middle; margin-right: 15px;">
      <template #trigger>
        <el-button plain type="primary" icon="upload">上传表格</el-button>
      </template>
</el-upload> -->

    </div>

    <el-form v-if="showDetails" :model="form" ref="form" label-width="90px" class="search-form">

      <div class="toolbox">
        <el-button type="primary" icon="plus" plain @click="save()">保存</el-button>
        <el-button @click="back">返回</el-button>
      </div>

      <el-row>
        <el-form-item label="入库类型" prop="billType">
          <el-col>
            <el-select v-model="form.billType" @change="changeBillType()" :disabled="billType.length > 0"
              placeholder="入库类型" style="width: 130px">
              <el-option v-for="item in dict.billTypes" :key="item.value" :label="item.label" :value="item.value"
                :disabled="item.value == '客户退货' || item.value == '退货入库'">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="所属公司">
          <el-col>
            <!-- <el-cascader v-model="form.companyID" :options="companys" /> -->
            <el-tree-select ref="companys" v-model="form.companyID" :data="companys" clearable check-strictly
              :disabled="billType.length > 0" :render-after-expand="false" style="min-width: 200px;" />
          </el-col>
        </el-form-item>
        <!-- <el-form-item label="库房">
        <el-col>
          <el-select v-model="form.storeCode" placeholder="请选择库房" style="width: 120px">
            <el-option v-for="item in dict.stores" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-col>
      </el-form-item> -->
        <el-form-item label="入库时间">
          <el-col>
            <el-input v-model="form.billDate" autocomplete="off" disabled style="width: 160px" placeholder="自动生成">
            </el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="入库单号">
          <el-col>
            <el-input v-model="form.billCode" autocomplete="off" disabled style="width: 150px" placeholder="自动生成">
            </el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="关联编号">
          <el-col>
            <el-input v-model="form.netCode" autocomplete="off" style="width: 160px" placeholder="外部关联编号"> </el-input>
          </el-col>
        </el-form-item>
      </el-row>
    </el-form>

    <div v-if="showDetails" class="billsdetial">
      <div class="table tableprint" ref="print">
        <el-table :data="tableData" stripe border width="1200" size="small">
          <el-table-column type="index" align="center" label="序号" width="60"> </el-table-column>
          <el-table-column prop="storeCode" label="库房" width="70">
            <!-- <template #default="scope">
              <el-select v-model="scope.row.storeCode" placeholder="库房" size="small" style="width:100%">
                <el-option v-for="item in dict.stores" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </template> -->
          </el-table-column>
          <el-table-column prop="channel" label="渠道" width="85" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="brandName" label="品牌" width="80" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="detailName" label="名称" width="150" show-overflow-tooltip> </el-table-column>
          <!-- <el-table-column prop="className" label="大类" width="80" show-overflow-tooltip> </el-table-column> -->
          <el-table-column prop="brandSerialCode" label="批次号" width="115" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="brandModelCode" label="品牌款号" width="105" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="stockNumber" label="数量" align="center" width="65" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="weight" width="65" label="克重" align="center" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="noTaxPurchasePrice" width="95" label="不含税采购价" align="right"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="taxPrice" width="70" label="税额" align="right" show-overflow-tooltip></el-table-column>
          <el-table-column prop="purchasePrice" width="90" label="含税采购价" align="right"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="productPrice" width="72" label="入库价" align="right"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="sellPrice" width="82" label="计划售价" align="right"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="unitName" label="规格" align="center" width="80" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="outOrderNo" label="原单号" width="120" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="platformOrderNo" label="平台单号" width="130" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="remark" label="备注" show-overflow-tooltip> </el-table-column>
          <el-table-column label="" width="100" fixed="right">
            <template #default="scope">
              <el-link @click="editItem(scope.row)" type="primary" size="small">编辑</el-link>
              <el-link @click="removeItem(scope.row)" type="primary" size="small" style="margin-left: 6px;">移除</el-link>
            </template>
          </el-table-column>
        </el-table>
        <el-row type="flex" class="inputfeed" justify="center">
          <el-col :span="5">
            操作： <el-input style="width: 60%" v-model="form.handoverName"></el-input>
          </el-col>
          <el-col :span="5">
            交接： <el-input style="width: 60%" v-model="form.reviewerName"></el-input>
          </el-col>
          <el-col :span="5">
            复核： <el-input style="width: 60%" v-model="form.operatorName"></el-input>
          </el-col>
          <el-col :span="5">
            财务： <el-input style="width: 60%" v-model="form.financerName"></el-input>
          </el-col>
        </el-row>
      </div>
      <!-- <el-button size="small" @click="onprint">
        打印 <i class="el-icon-printer"> </i>
      </el-button> -->
      <div class="billsbutton">
        <el-button type=""
          @click="itemData = { stockNumber: 1, random: Math.random() }; chooseSku = {}; showItem = true">
          <el-icon>
            <CirclePlus />
          </el-icon>
          &nbsp;手动增加入库货品 </el-button>

        <el-button type="">
          <el-upload ref="upload" action="#" :on-change="handleChange" :on-remove="handleRemove"
            :on-exceed="handleExceed" :file-list="fileList" :http-request="submitUpload" :auto-upload="true" :limit="1"
            style="display:inline-flex;margin-right: 0px;">
            <el-icon>
              <RefreshLeft />
            </el-icon>
            &nbsp;重新上传表格
          </el-upload>
        </el-button>
      </div>
    </div>

    <el-dialog title="添加入库货品" width="800" v-model="showItem" destroy-on-close>
      <el-form :model="itemData" label-width="110">
        <el-row :gutter="0">
          <el-col :span="12">
            <el-form-item prop="storeCode" label="入库库房">
              <el-select v-model="itemData.storeCode" placeholder="库房" style="width:180px">
                <el-option v-for="item in dict.stores" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="channel" label="渠道">
              <el-select v-model="itemData.channel" placeholder="渠道" style="width: 180px">
                <el-option v-for="dd in dict.channels" :key="dd.value" :label="dd.label" :value="dd.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="brandModelCode" label="选择入库款式">
              <el-autocomplete style="width: 75%" v-model="chooseSku.value" :fetch-suggestions="searchSkuAsync"
                placeholder="选择对应的产品SKU信息" @select="chooseSkuHandle">
              </el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="detailCode" label="系统款号">
              <el-input v-model="itemData.detailCode" disabled style="width:180px;" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="skuSerialCode" label="自定义款号">
              <el-input v-model="itemData.skuSerialCode" disabled style="width:180px;" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="detailName" label="款式名称">
              <el-input v-model="itemData.detailName" disabled style="width:80%;" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="brandName" label="品牌名称">
              <el-select v-model="itemData.brandName" disabled placeholder="请维护款式品牌信息" style="width:180px">
                <el-option v-for="item in dict.brands" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="brandModelCode" label="品牌款号">
              <el-input v-model="itemData.brandModelCode" disabled style="width:150px;" />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
          <el-form-item prop="className" label="大类">
            <el-input v-model="itemData.className" style="width:180px;" />
          </el-form-item>
        </el-col> -->
          <el-col :span="12">
            <el-form-item prop="factoryName" label="工厂名称">
              <el-select v-model="itemData.factoryName" disabled placeholder="请维护款式工厂信息" style="width:180px">
                <el-option v-for="item in dict.factorys" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="factoryModelCode" label="工厂款号">
              <el-input v-model="itemData.factoryModelCode" disabled style="width:150px;" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="unitName" label="规格/单位">
              <el-input v-model="itemData.unitName" style="width:200px;" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="weight" label="克重/重量">
              <el-input-number v-model="itemData.weight" :min="0" :max="10000000" placeholder="0.00" :precision="2"
                style="width:145px;"></el-input-number>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="serialCode" label="批次号/货号">
              <el-input v-model="itemData.serialCode" show-word-limit minlength="4" maxlength="25" style="width:200px;"
                @input="itemData.brandSerialCode = itemData.serialCode" placeholder="系统批次号/货号" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="brandSerialCode" label="品牌货号">
              <el-input v-model="itemData.brandSerialCode" style="width:200px;" disabled placeholder="品牌批次号/货号" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="certNumber" label="证书号">
              <el-input v-model="itemData.certNumber" style="width:200px;" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="stockNumber" label="入库数量">
              <el-input-number v-model="itemData.stockNumber" :min="0" :max="10000000" placeholder="0.00" :precision="2"
                style="width:145px;"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="noTaxPurchasePrice" label="不含税采购价">
              <el-input-number v-model="itemData.noTaxPurchasePrice" :min="0" :max="10000000" placeholder="0.00"
                :precision="2" style="width:180px;"
                @change="itemData.purchasePrice = ((itemData.noTaxPurchasePrice || 0) + (itemData.taxPrice || 0)).toFixed(2)"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="taxPrice" label="税额">
              <el-input-number v-model="itemData.taxPrice" :min="0" :max="10000000" placeholder="0.00" :precision="2"
                style="width:180px;"
                @change="itemData.purchasePrice = ((itemData.noTaxPurchasePrice || 0) + (itemData.taxPrice || 0)).toFixed(2)"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="purchasePrice" label="含税采购价">
              <el-input-number v-model="itemData.purchasePrice" :min="0" :max="10000000" placeholder="0.00" disabled
                :precision="2" style="width:180px;"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="productPrice" label="入库价">
              <el-input-number v-model="itemData.productPrice" :min="0" :max="10000000" placeholder="0.00"
                :precision="2" style="width:180px;"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="sellPrice" label="计划售价">
              <el-input-number v-model="itemData.sellPrice" :min="0" :max="10000000" placeholder="0.00" :precision="2"
                style="width:180px;"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="outOrderNo" label="原发货单号">
              <el-input v-model="itemData.outOrderNo" style="width:220px;" placeholder="退货入库等类型必填" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="platformOrderNo" label="平台单号">
              <el-input v-model="itemData.platformOrderNo" style="width:220px;" placeholder="退货入库等类型必填" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="remark" label="备注">
              <el-input v-model="itemData.remark" style="width:80%;" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showItem = false" size="large">取 消</el-button>
          <el-button type="primary" @click="addItem()" size="large"> 确 定 </el-button>
        </span>
      </template>
    </el-dialog>

  </el-card>
</template>
<script>
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
import { submitPreIn, fetchBillCode } from "@/api/stock";
import { fetchSkuList } from "@/api/product";
import { uploader, inReviewer } from "@/api/subupload";
export default {
  name: "instore",
  components: { StaticDialog, StaticTable },
  //emits: ["freshen"],
  data() {
    return {
      templateUrl: process.env.VUE_APP_BASEURL + "/static/template/stockin.xlsx",
      fileList: [],
      loading: false,
      resList: {},
      dataId: "",
      tableData: [],
      itemData: {},
      chooseSku: {},

      showDetails: false,
      showItem: false,

      dict: {
        brands: [],
        billTypes: [],
        stores: [],
        channels: [],
        factorys: []
      },

      form: {
        billType: "", //入库类型
        storeCode: "", //入库库房
        billDate: "", //时间
        billCode: "", //入库单据号
        netCode: "", //入网单

        handoverName: "",
        reviewerName: "",
        operatorName: "",
        financerName: "",
        companyID: ''
      },
      companys: [],
      billType: ''
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    //添加文件事件
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    //删除文件事件
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    //超出个数事件
    handleExceed(file, fileList) {
      this.$message({
        message: "每次只能上传一个文件",
        type: "warning",
      });
    },
    //上传操作
    submitUpload(file) {
      this.loading = true;
      var dataList = new FormData();
      dataList.append(file.file.name, file.file);
      dataList.append("fileType", "excel");
      dataList.append("dataType", "BillIn");
      this.$refs.upload.clearFiles();

      uploader(dataList)
        .then((res) => {
          if (res.code === 200) {
            this.$message({ message: "上传成功", type: "success", });
            this.dataId = res.data[0].id;
          } else {
            this.$message.error("上传失败，请重试");
          }
        })
        .then(() => {
          this.loading = false;
          inReviewer(this.dataId).then(
            (res) => {
              if (res.code === 200) {
                this.tableData = Object.values({ ...res.data });
                this.showDetails = true;
                //this.$emit("freshen");
              }
            },
            (err) => {
              this.resList = err.data;
            }
          );
        });

      this.fileList = [];
    },
    //获取单据编号
    changeBillType() {
      let that = this;
      let items = this.dict.billTypes.filter(function (item) {
        return item.name == that.form.billType;
      });
      console.log(that.form.billType, items, this.dict.billTypes);
      if (items && items.length > 0) {
        fetchBillCode({
          billType: items[0].value,
          prev: items[0].attribute,
        }).then((res) => {
          that.form.billCode = res.data + "-" + that.tableData.length;
          that.form.billDate = that.$util.fmtDate(new Date());
        });
      }
    },
    //打印
    onprint() {
      print(this.$refs.print);
    },
    //入库
    save() {
      let that = this;
      if (this.tableData.length <= 0) {
        this.$message({ message: "请导入或者输入至少一条入库数据", type: "warning", });
        return;
      }
      if (this.form.billType == "") {
        this.$message({ message: "请选择本次入库类型", type: "warning", });
        return;
      }

      if (this.form.companyID.length == 0) {
        this.$message({ message: "请选择所属公司", type: "warning", });
        return;
      }

      //this.form.companyID = this.form.companyID[this.form.companyID.length - 1];

      // if (this.form.storeCode == "") {
      //   this.$message({ message: "请选择本次入库库房", type: "warning", });
      //   return;
      // }

      if (this.form.billType == "" || this.form.billDate == "" || this.form.billCode == "") {
        this.$message({ message: "必填信息不可为空", type: "warning", });
        return;
      }
      this.form.billCode = this.form.billCode.split('-')[0] + "-" + this.tableData.length;

      submitPreIn({ bill: this.form, bounds: this.tableData }).then((res) => {
        that.$message({ type: res.type, message: res.content, });
        //this.$emit("freshen");
        this.form = {};
        //this.showDetails = false;
        this.back();
      });
    },
    clear() {
      this.showDetails = false;
      this.tableData = [];
    },
    //选择选中事件
    chooseSkuHandle(item) {
      this.chooseSku = item;
      this.itemData.detailId = item.id;
      this.itemData.detailCode = item.detailCode;
      this.itemData.detailName = item.detailName;
      this.itemData.skuSerialCode = item.serialCode;
      this.itemData.unitName = item.detailUnit;

      this.itemData.brandCode = item.brandCode;
      this.itemData.brandName = item.brandName;
      this.itemData.brandModelCode = item.brandModelCode;

      this.itemData.factoryCode = item.factoryCode;
      this.itemData.factoryName = item.factoryName;
      this.itemData.factoryModelCode = item.factoryModelCode;
    },
    //搜索归属的SPU（请求后台数据）
    searchSkuAsync(queryString, callback) {
      let search = {
        page: 1,
        rows: 10,
        columns: [
          { prop: "detailCode", searchable: true },
          { prop: "detailName", searchable: true },
          { prop: "detailUnit", searchable: true },
          { prop: "serialCode", searchable: true },

          { prop: "brandCode" },
          { prop: "brandName" },
          { prop: "brandModelCode", searchable: true },

          { prop: "factoryModelCode", searchable: true },
          { prop: "factoryCode" },
          { prop: "factoryName" },
        ],
        keyword: this.chooseSku.value,
      };
      fetchSkuList(search).then((res) => {
        let inputOptions = [];
        res.data.data.forEach(function (item) {
          inputOptions.push({
            value: item.detailName + "[" + item.detailCode + "]" + (item.serialCode ? "[" + item.serialCode + "]" : ''),
            ...item,
          });
        });

        callback(inputOptions);
      });
    },
    addItem() {
      this.itemData.purchasePrice = ((this.itemData.noTaxPurchasePrice || 0) + (this.itemData.taxPrice || 0)).toFixed(2);

      if (!this.itemData.storeCode || !this.itemData.channel) {
        this.$message("请选择入库货品的库房及渠道信息");
        return;
      }
      if (!this.itemData.detailCode || !this.itemData.detailName) {
        this.$message("请选择入库货品的所属款号信息");
        return;
      }
      if (!this.itemData.weight || this.itemData.weight < 0) {
        this.$message("请填写入库货品的重量信息");
        return;
      }
      if (!this.itemData.brandSerialCode || !this.itemData.serialCode) {
        this.$message("请填写入库货品的货号/批次号信息");
        return;
      }
      if (!this.itemData.stockNumber || this.itemData.stockNumber <= 0) {
        this.$message("请填写入库货品的数量");
        return;
      }
      if (!this.itemData.purchasePrice || this.itemData.purchasePrice <= 0
        || !this.itemData.productPrice || this.itemData.productPrice <= 0
        || !this.itemData.sellPrice || this.itemData.sellPrice <= 0) {
        this.$message("请填写入库货品的价格信息");
        return;
      }

      this.showItem = false;
      let rowData = this.tableData.filter(res => res.random == this.itemData.random);
      if (rowData.length == 0)
        this.tableData.push(this.itemData);
      this.itemData = {};
    },
    editItem(row) {
      this.chooseSku = {};
      this.itemData = row;
      this.showItem = true;
    },
    removeItem(row) {
      this.tableData = this.tableData.filter(res => res.random != row.random);
    }
  },
  mounted() {
    this.$kaung.dictionary("stock", "brand").then((res) => { this.dict.brands = res; });
    this.$kaung.dictionary("stock", "factory").then((res) => { this.dict.factorys = res; });
    this.$kaung.dictionary("stock", "inbound").then((res) => { this.dict.billTypes = res; }).then(() => {
      if (this.$route.query.billType) {
        this.billType = this.form.billType = this.$route.query.billType;
        this.changeBillType();
      }
    });
    this.$kaung.dictionary("stock", "store").then(res => this.dict.stores = res);
    this.$kaung.dictionary("stock", "channel").then((res) => { this.dict.channels = res; });

    if (this.$route.query.v)
      this.showDetails = true;

    if (this.$route.query.data) {
      this.tableData = JSON.parse(this.$route.query.data);
      this.form.companyID = this.tableData[0].companyID;
    }

    this.$kaung.companys().then((res) => {
      this.companys = res;

    });
  },
};
</script>
<style lang="less">
.toolbox {
  position: absolute;
  right: 10px;
  z-index: 9;
  right: 100px;
}

.storedialog {
  width: 100%;
  height: 100%;
  min-width: 400px;
  background: #fff;
  text-align: center;

  .download {
    font-size: 20px;
    display: block;
    padding: 10px 0;
  }

  .errtext {
    color: red;
    line-height: 30px;
  }
}

.billsdetial {
  margin: 0 auto;

  .tableprint {
    width: 100%;
    margin: 0 auto;

    .el-select {
      width: 100px;

      .input,
      .el-input__inner {
        border-width: 0;
        // border-bottom: 1px solid #dcdfe6;
        border-radius: 0;
        background: rgba(0, 0, 0, 0);
      }
    }
  }

  .inputfeed {
    margin: 20px auto;
    text-align: center;

    .input,
    .el-input__inner {
      border-width: 0;
      // border-bottom: 1px solid #dcdfe6;
      border-radius: 0;
      background: rgba(0, 0, 0, 0);
    }
  }

  .billsbutton {
    text-align: center;
    margin-bottom: 10px;
  }
}

@media print {
  .tableprint {
    width: 100%;
    margin: 0 auto;

    .el-select {
      width: 100px;

      .input,
      .el-input__inner {
        border-width: 0;
        border-bottom: 1px solid #dcdfe6;
        border-radius: 0;
        background: rgba(0, 0, 0, 0);
      }
    }
  }

  .inputfeed {
    margin-top: 20px;
    text-align: center;

    .input,
    .el-input__inner {
      border-width: 0;
      border-bottom: 1px solid #dcdfe6;
      border-radius: 0;
      background: rgba(0, 0, 0, 0);
    }
  }
}
</style>