import request from '@/utils/request'
import util from '@/utils/util'

var kaung = {
  path: '',
  init(path) {
    this.path = '/' + path;
    return this;
  },
  fetch(options) {
    options.method = options.method || 'post';
    return request(options);
  },
  /**
   * 通用分页请求方法
   * @param {*} params 
   * @returns 
   */
  search(params) {
    return request({
      url: this.path + '/Search',
      method: 'post',
      data: params
    });
  },

  /**
   * 通用下载方法
   * @param {*} params 
   * @returns 
   */
  export(params) {
    return request({
      url: this.path + '/Export',
      method: 'post',
      data: params
    });
  },

  getone(id) {
    return request({
      url: this.path + '/' + id,
      method: 'get'
    });
  },

  /**
   * 新增或保存数据
   * @param {*} params 
   * @returns 
   */
  submit(params) {
    return request({
      url: this.path + '/' + (params.id || ''),
      method: params.id ? 'put' : 'post',
      data: params.data
    });
  },
  post(params) {
    return request({
      url: params.url || params.path || this.path,
      method: params.method || 'post',
      data: params.data
    });
  },

  /**
   * 通用删除方法
   * @param {*} id 
   * @returns 
   */
  delete(id) {
    return request({
      url: this.path + '/' + (id || ''),
      method: 'delete'
    });
  },

  transfer(params) {
    return request({
      url: '/Common/Transfer',
      method: 'post',
      data: params
    })
  },

  template(columns) {
    return request({
      url: '/Common/Template',
      method: 'post',
      data: columns
    });
  },

  companys() {
    return request({
      url: '/BaseCompany/Tree?v=' + util.getVersion(),
      method: 'get',
      cache: (60 * 60 * 24 * 5)
    }).then((res) => {
      return res.code === 200 ? res.data : null;
    });
  },
  departments(companyId) {
    return request({
      url: '/BaseDepartment/Tree?CompanyId=' + companyId + '&v=' + util.getVersion(),
      method: 'get',
      cache: (60 * 60 * 24 * 5)
    }).then((res) => {
      return res.code === 200 ? res.data : null;
    });
  },
  dictionary(category, code) {
    return request({
      url: '/BaseDict/List?v=' + util.getVersion(),
      params: {
        category: category || '',
        code: code || ''
      },
      method: 'get',
      cache: 60 * 60 * 24 * 7 //7天的缓存
    }).then((res) => {
      var options = [];
      res.data.forEach(function (item) {
        options.push({
          "name": item.name,
          "value": item.value,
          "code": item.code,
          "category": item.category,
          "attribute": item.attribute,
          "id": item.id
        });
      });
      return options;
    });
  },
  // billcode() {
  //   return request({
  //     url: this.path + '/BillCode',
  //     method: 'get'
  //   });
  // },


  pushlogs(data) {
    let bizData = {
      ...{ url: window.location.href, displayName: "dashboard", controler: "", body: "", result: "" },
      ...data,
    };
    return request({
      url: '/SysApiLog/',
      method: 'post',
      data: bizData
    }).then((res) => {
      console.log(bizData, res)
    });
  }
};

export default kaung
