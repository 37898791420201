<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">售后订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>售后订单列表（处理流程：创建售后订单 > 收货 > 质检 > 确认完结）</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form label-width="90px" :model="querys" ref="querys" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" @keyup.enter="fetchs" prefix-icon="Search"
              style="width:220px"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="订单时间" label-width="90px">
          <el-col>
            <el-date-picker v-model="dateRange" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"
              unlink-panels :shortcuts="$util.getShortCuts()" @change="fetchs()" style="max-width:210px;">
            </el-date-picker>
          </el-col>
        </el-form-item>
        <!-- v-if="status==''" -->
        <el-form-item label="状态" prop="status" label-width="90px">
          <el-col style="width: 100px">
            <el-select v-model="status" clearable placeholder="请选择" @change="fetchs()">
              <!-- 10 已审核  -->
              <el-option key="10" label="待确认" value="10"></el-option>
              <el-option key="50" label="处理中" value="50"></el-option>
              <el-option key="200" label="已完成" value="200"></el-option>
              <!-- 退货终止  -->
              <el-option key="-2" label="已拒绝" value="-2"></el-option>
              <!-- 退货异常  -->
              <el-option key="-10" label="已关闭" value="-10"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="创建时间" label-width="90px">
          <el-col>
            <el-date-picker v-model="importDateRange" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"
              unlink-panels :shortcuts="$util.getShortCuts()" @change="fetchs()" style="max-width:210px;">
            </el-date-picker>
          </el-col>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="所属公司">
          <el-col>
            <!-- <el-cascader v-model="companyId" :options="companys" clearable @change="fetchs()" /> -->
            <el-tree-select ref="companys" v-model="companyId" :data="companys" @change="fetchs();" clearable
              check-strictly :render-after-expand="false" style="min-width: 220px;" />
          </el-col>
        </el-form-item>

        <el-form-item label="订单渠道" prop="platform" label-width="90px">
          <el-col style="width: 150px">
            <el-select v-model="platform" clearable placeholder="请选择" @change="fetchs()" allow-create filterable
              style="max-width: 200px">
              <!-- <el-select v-model="platform" placeholder="渠道" clearable style="max-width: 200px" @change="fetchs()"> -->
              <el-option v-for="dd in dict.platforms" :key="dd.value" :label="dd.label" :value="dd.value">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="发货批次号" prop="serialCodes" label-width="120px">
          <el-col>
            <el-input-tag v-model="serialCodes" placeholder="输入批次号进行批量搜索回车确认" aria-label="请在输入之后回车确认" @change="fetchs()"
              style="width:230px" />
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">搜索</el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button plain type="primary" v-if="status == 0 || status == 10" icon="upload"
        @click="openUploadDialog()">导入售后单</el-button>
      <el-button plain type="primary" v-if="status == 0 || status == 10" icon="plus"
        @click="addOrderEvent()">手动创建售后单</el-button>

      <!-- 售后单待确认 且 售后类型是 发货前退款或者仅退款，审核后可以直接完结关闭售后单 -->
      <!-- <el-button plain
        v-if="currRow && (currRow.status == null || currRow.status == 0 || currRow.status == 10) && (currRow.returnType == 0 || currRow.returnType == 20)"
        type="primary" icon="CircleCheck" @click="confirmOrderEvent()">
        完结售后单
      </el-button> -->
      <!-- 售后单待确认 且 售后类型是 涉及到和货品有关需要供应链介入的情况 -->
      <!-- <el-button plain
        v-if="currRow && (currRow.status == null || currRow.status == 0 || currRow.status == 10) && (currRow.returnType == 10 || currRow.returnType == 30 || currRow.returnType == 50)"
        type="primary" icon="CircleCheckFilled" @click="confirmOrderEvent()">
        确认售后单
      </el-button> -->

      <el-button plain v-if="selects.length > 0" type="primary" icon="CircleCheckFilled"
        @click="confirmOrderEvent()">审核售后单</el-button>

      <!-- <el-button plain
        v-if="currRow && currRow.status == 200 && (currRow.returnType == 10 || currRow.returnType == 30 || currRow.returnType == 50)"
        type="info" icon="CircleCheckFilled" @click="stockReturnEvent()">退货入库</el-button> -->
      <el-button plain type="info" icon="CircleCheckFilled" @click="stockReturnEvent()">退货入库</el-button>

      <el-button plain type="danger" icon="close" v-if="currRow && currRow.status != -10"
        @click="closeOrderEvent()">关闭售后单</el-button>
      <el-button plain type="danger" icon="delete" v-if="currRow && currRow.status == -10"
        @click="deleteOrderEvent()">删除售后单</el-button>
    </div>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
      :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler" :seleHandler="seleHandler">

      <template v-slot:returnNo="scope">
        <div v-if="scope.row.returnNo">{{ scope.row.returnNo }}</div>
        <div v-else-if="scope.row.platformReturnNo">{{ scope.row.platformReturnNo }}</div>
      </template>

      <template v-slot:status="scope">
        <el-link v-if="scope.row.status == 0 || scope.row.status == null" type="primary" size="small"
          disabled>默认</el-link>
        <el-link v-else-if="scope.row.status == 10" type="info" size="small" disabled>待确认</el-link>
        <el-link v-else-if="scope.row.status == 50" type="primary" size="small" disabled>处理中</el-link>
        <el-link v-else-if="scope.row.status == 200" type="success" size="small" disabled>已完成</el-link>
        <el-link v-else-if="scope.row.status == -2" type="danger" size="small" disabled>已拒绝</el-link>
        <el-link v-else-if="scope.row.status == -10" type="danger" size="small" disabled>已关闭</el-link>
        <el-link v-else type="info" size="small" disabled>错误</el-link>
      </template>

      <template v-slot:returnType="scope">
        <el-link v-if="scope.row.returnType == 0" type="success" size="small" disabled>发货前退款</el-link>
        <el-link v-else-if="scope.row.returnType == 10" type="primary" size="small" disabled>退货退款</el-link>
        <el-link v-else-if="scope.row.returnType == 20" type="danger" size="small" disabled>仅退款</el-link>
        <el-link v-else-if="scope.row.returnType == 30" type="primary" size="small" disabled>售后换新</el-link>
        <el-link v-else type="info" size="small" disabled>其他类型</el-link>
      </template>

      <template v-slot:toolbar="scope">
        <el-link @click="watchOrderEvent(scope.row)" type="primary" size="small">查看</el-link>
      </template>
    </static-table>
  </el-card>

  <static-dialog ref="detailDialog" class="dialog" title="售后订单详情" :ok="saveOrderEvent" :width="'80%'">
    <el-form :model="forms" :rules="rules" ref="forms" :label-width="110">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="售后货品">
            <el-col :span="6">
              原订单号：<span class="space20">{{ forms.orderNo }}</span>
            </el-col>
            <el-col :span="6">
              订单时间：<span class="space20">{{ forms.platformOrderDate }}</span>
            </el-col>
            <el-col :span="5">
              订单平台：<span class="space20">{{ forms.platformName }}</span>
            </el-col>
            <el-col :span="6">
              主单号：<span class="space20">{{ forms.platformOrderNo }}</span>
            </el-col>
            <el-col :span="6">
              子单号：<span class="space20">{{ forms.platformOrderSubNo }}</span>
            </el-col>

            <el-col :span="24">
              产品名称：<span class="space20">{{ forms.platformProductName }}</span>
            </el-col>
            <el-col :span="6">
              品牌名称：<span class="space20">{{ forms.brandName }}</span>
            </el-col>
            <el-col :span="6">
              品牌款号：<span class="space20">{{ forms.brandModelCode }}</span>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="" prop="returnType">
            <span style="color:red;">退货及售后换新的售后类型，在售后单货品质检完结审核之后需要手动确认货品的退货入库信息。其他售后类型如有货品退回情况请手动进行售后入库流程；</span>
          </el-form-item>
        </el-col>

        <el-col :span="7">
          <el-form-item label="平台单号" prop="PlatformOrderNo">
            <el-input v-model="forms.platformOrderNo" style="max-width:250px" placeholder="" />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="子单号" prop="PlatformOrderSubNo">
            <el-input v-model="forms.platformOrderSubNo" style="max-width:200px" placeholder="" />
          </el-form-item>
        </el-col>

      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="售后类型" prop="returnType">
            <el-select v-model="forms.returnType" placeholder="请选择售后类型" @change="returnTypeChange()">
              <el-option key=0 label="发货前退款" :value="parseInt(0)"></el-option>
              <el-option key=10 label="退货退款" :value="parseInt(10)"></el-option>
              <el-option key=20 label="仅退款" :value="parseInt(20)"></el-option>
              <el-option key=30 label="售后换新" :value="parseInt(30)"></el-option>
              <el-option key=50 label="其他(维修等)" :value="parseInt(50)"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!--只有实际货品要退换才需要填写库房/数量等-->
        <el-col :span="6">
          <el-form-item label="售后渠道">
            <el-select v-model="forms.channel" placeholder="渠道" :disabled="forms.channel" style="max-width: 130px">
              <el-option v-for="dd in dict.channels" :key="dd.value" :label="dd.label" :value="dd.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="退回库房">
            <el-select v-model="forms.storeCode" placeholder="库房" style="max-width: 180px" :disabled="noGoods()">
              <el-option v-for="dd in dict.stores" :key="dd.value" :label="dd.label" :value="dd.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
        </el-col>
        <el-col :span="6">
          <el-form-item label="售后款号" prop="detailCode">
            <el-input v-model="forms.detailCode" style="max-width:180px" :disabled="noGoods()" placeholder="" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="发货批次号" prop="serialCode">
            <el-input v-model="forms.serialCode" style="max-width:150px" :disabled="noGoods()" placeholder="" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="退货数量及金额" prop="returnQty">
            <el-input-number v-model="forms.returnQty" :min="0" :max="10000000" :precision="0" placeholder="数量"
              style="max-width:120px" :disabled="noGoods()"></el-input-number>
            <el-input-number v-model="forms.returnFee" :min="0" :max="10000000" :precision="2" placeholder="金额"
              style="max-width:170px;margin-left: 20px;" :disabled="noGoods()"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="!noGoods()">
        <el-col :span="6">
          <el-form-item label="赠品仓库" prop="fitStoreCode">
            <el-input v-model="forms.fitStoreCode" style="max-width:180px" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="赠品款号" prop="fitDetailCode">
            <el-input v-model="forms.fitDetailCode" style="max-width:180px" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="赠品货号" prop="fitSerialCode">
            <el-input v-model="forms.fitSerialCode" style="max-width:180px" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10" v-if="!noGoods()">
        <el-col :span="6">
          <el-form-item label="寄回快递" prop="returnPostName">
            <el-input v-model="forms.returnPostName" style="max-width:180px" placeholder="快递公司名称" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="快递单号" prop="returnPostNo">
            <el-input v-model="forms.returnPostNo" style="max-width:180px" placeholder="快递单号" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="运费模式">
            <el-select v-model="forms.returnPostModel" placeholder="请选择运费模式">
              <el-option key="用户承担" label="用户承担" value="用户承担"></el-option>
              <el-option key="商家承担" label="商家承担" value="商家承担"></el-option>
              <el-option key="平台承担" label="平台承担" value="平台承担"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <hr style="margin:0 0 12px;">
        </el-col>
        <el-col :span="12">
          <el-form-item label="售后订单号" prop="returnNo">
            <el-input v-model="forms.returnNo" style="max-width:280px" placeholder="渠道平台的售后单号" />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="售后原因" prop="returnReason">
            <el-input v-model="forms.returnReason" style="max-width:480px" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注">
            <el-input v-model="forms.remark" style="max-width:450px" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </static-dialog>


  <static-dialog ref="uploadDialog" class="dialog" title="上传渠道平台售后单">
    <div class="load-dialog" style="text-align:center; max-width:500px;margin:0px auto;">
      <h4>上传前请检查文件格式模板</h4>
      <br>

      <el-select v-model="templateValue" placeholder="" style="margin:10px auto;">
        <el-option key="normal" label="标准售后模板" value="normal" />
        <el-option key="zhaohang" label="招行售后模板格式" value="zhaohang" />
        <el-option key="guangfa" label="广发售后模板格式" value="guangfa" />
      </el-select>

      <el-upload v-loading="loading" class="upload-box" ref="upload" action="#" drag :on-change="handleChange"
        :on-remove="handleRemove" :on-exceed="handleExceed" :file-list="fileList" :http-request="submitUpload"
        :auto-upload="true" :limit="1">
        <el-icon style="font-size:3em;">
          <UploadFilled />
        </el-icon>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip">
          注：Excel表格里的填写项请严格按照模板内容进行填写
        </div>
      </el-upload>

      <el-link type="primary" @click="download()">点击下载售后单导入模板.xlsx</el-link>
    </div>
  </static-dialog>

</template>

<script>
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
import { fetchReturnInfos, saveReturnOrder, closeReturnOrder, deleteReturnOrder, confirmReturnOrders, stockinReturnData, stockOriginData, importOrderReturn } from "@/api/order";
import { uploader } from "@/api/subupload";
export default {
  name: "orderReturnList",
  components: { StaticDialog, StaticTable },
  data() {
    return {
      querys: {
        keyword: "",
        filters: [],
        columns: [
          { type: "selection", width: "55" },
          { type: "index", label: "序号" },
          { type: "hidden", prop: "companyId", label: "公司ID", showOverflowTooltip: true },
          { type: "template", prop: "returnNo", label: "售后单号", width: "190", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "orderNo", label: "原订单号", width: "190", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "platformOrderNo", label: "原平台单号", width: "190", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "platformOrderSubNo", label: "原子单号", width: "190", sortable: true, searchable: true, showOverflowTooltip: true },
          { type: "template", prop: "status", label: "状态", width: "80" },
          { type: "template", prop: "returnType", label: "售后类型", width: "90" },
          { prop: "platformName", label: "平台名称", width: "90", sortable: true, showOverflowTooltip: true },
          { prop: "platformOrderDate", label: "订单日期", width: "145", sortable: true, showOverflowTooltip: true },
          { prop: "applicationDate", label: "申请日期", width: "145", sortable: true, showOverflowTooltip: true },

          { prop: "platformProductName", label: "名称", width: "140", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "detailCode", label: "系统款号", width: "120", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "brandModelCode", label: "品牌款号", width: "110", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "serialCode", label: "货品条码", width: "110", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "returnReason", label: "售后原因", width: "110", searchable: true, showOverflowTooltip: true },

          { prop: "returnQty", label: "数量", width: "70", },
          { prop: "returnFee", label: "金额", width: "70", },

          { prop: "returnPostName", label: "快递名称", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "returnPostNo", label: "快递单号", width: "130", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "returnPostModel", label: "运费模式", width: "80", showOverflowTooltip: true },
          { prop: "returnPostFee", label: "运费", width: "60", },


          // { prop: "customerName", label: "顾客姓名", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          // { prop: "customerMobile", label: "手机号", width: "100", sortable: true, searchable: true, showOverflowTooltip: true },
          // { prop: "customerProvince", label: "省份", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          // { prop: "customerCity", label: "城市", width: "85", sortable: true, searchable: true, showOverflowTooltip: true },
          // { prop: "customerArea", label: "区县", width: "80", searchable: true, showOverflowTooltip: true },
          // { prop: "customerTown", label: "街道/镇", width: "90", searchable: true, showOverflowTooltip: true },
          // { prop: "customerAddress", label: "详细地址", width: "120", searchable: true, showOverflowTooltip: true },
          // { prop: "customerZipCode", label: "邮编", width: "70", showOverflowTooltip: true },

          { prop: "remark", label: "商家备注", width: "110", searchable: true, showOverflowTooltip: true },

          { prop: "storeCode", label: "库房", width: "110", searchable: true, showOverflowTooltip: true },
          { prop: "channel", label: "渠道", width: "110", searchable: true, showOverflowTooltip: true },

          { prop: "fitStoreCode", label: "赠品仓库", width: "70", showOverflowTooltip: true },
          { prop: "fitDetailCode", label: "赠品款号", width: "70", showOverflowTooltip: true, },
          { prop: "fitSerialCode", label: "赠品货号", width: "70", showOverflowTooltip: true, },

          { prop: "orderFrom", label: "数据来源", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },

          { prop: "createDate", label: "导入时间", width: "145", sortable: true, },
          { type: "toolbar", label: "操作", width: "80" },
        ],
      },
      pager: {
        sidx: "applicationDate", //默认的排序字段
        sord: "descending",
      },
      forms: {
        returnOrders: [],
        channel: null,
        companyId: null
      },
      templateValue: 'normal',
      method: 'add',
      currRow: null,
      selects: [],
      status: "0",
      platform: '',

      dateRange: [],
      importDateRange: [],

      dict: {
        stores: [],
        platforms: [],
        channels: [],
      },

      companys: [],
      companyId: '',
      loading: false,
      fileList: [],

      rules: {
        // returnReason: [
        //   { required: true, message: "请填写具体退货原因", trigger: "blur" },
        // ],
      },

      serialCodes: [],
    };
  },
  methods: {
    fetchs(methodName) {
      let that = this;
      if ((typeof methodName) == 'object') methodName = null;
      this.querys.filters = [];
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };

      if (this.status) {
        this.querys.filters.push({
          name: "status",
          value: this.status,
          operate: 0,
        });
      }
      if (this.platform) {
        this.querys.filters.push({
          name: "platformName",
          value: '%' + this.platform + '%',
          operate: 5,
        });
      }

      if (this.forms.channel) {
        this.querys.filters.push({
          name: "channel",
          value: this.forms.channel,
          operate: 0,
        });
      }

      if (this.dateRange && this.dateRange[0])
        this.querys.filters.push({
          name: "platformOrderDate",
          value: this.$util.fmtDate(this.dateRange[0], "yyyy-MM-dd"),
          operate: 2,
        });
      if (this.dateRange && this.dateRange[1])
        this.querys.filters.push({
          name: "platformOrderDate",
          value: this.$util.fmtDate(this.dateRange[1], "yyyy-MM-dd") + " 23:59:59",
          operate: 4,
        });

      if (this.importDateRange && this.importDateRange[0])
        search.filters.push({
          name: "createDate",
          value: this.$util.fmtDate(this.importDateRange[0], "yyyy-MM-dd"),
          operate: 2,
        });
      if (this.importDateRange && this.importDateRange[1])
        search.filters.push({
          name: "createDate",
          value: this.$util.fmtDate(this.importDateRange[1], "yyyy-MM-dd") + " 23:59:59",
          operate: 4,
        });


      if (this.companyId != null && this.companyId.length > 0) {
        this.querys.filters.push({
          name: "companyId",
          value: this.companyId,
          operate: 6,
        });
      }
      if (this.serialCodes != null && this.serialCodes.length > 0) {
        this.querys.filters.push({
          name: "serialCode",
          value: this.serialCodes.join(','),
          operate: 6,
        });
      }

      search.method = methodName || '';
      fetchReturnInfos(search).then((res) => {
        this.pager = { ...res.data };
      })
        .catch(() => {
          that.$message({ type: "info", message: "查询失败", });
        });
    },
    addOrderEvent() {
      this.$router.push('addreturn');
    },
    watchOrderEvent(row) {
      //this.$router.push('addreturn');
      this.forms = row;
      console.log(this.forms);
      this.$refs.detailDialog.toggle();
    },
    deleteOrderEvent() {
      if (this.currRow == null) {
        this.$message({ message: '请选择对应订单数据行' });
        return;
      }
      let that = this;
      this.$confirm("确认删除勾选的订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteReturnOrder(this.currRow.id).then((res) => {
            if (res.code === 200) {
              that.$message({ type: "success", message: res.content, });
              that.fetchs();
            }
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "删除失败", });
        });
    },
    closeOrderEvent() {
      if (this.currRow == null) {
        this.$message({ message: '请选择对应订单数据行' });
        return;
      }
      let that = this;

      this.$prompt("请输入售后单关闭的原因 (用户取消退货/取消退款等情况)?", "关闭售后单不会影响实际发货", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: '关闭原因不可为空',
        type: "warning",
      })
        .then(({ value }) => {
          console.log(value);
          closeReturnOrder(this.currRow, value).then((res) => {
            if (res.code === 200) {
              that.$message({ type: "success", message: res.content, });
              that.fetchs();
            }
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "关闭失败", });
        });
    },
    confirmOrderEvent() {
      // if (this.currRow == null) {
      //   this.$message({ message: '请选择对应订单数据行' });
      //   return;
      // }
      // if (selectedTypes.length > 1) {
      //   this.$message({ message: '一次仅允许审核同一售后类型的订单' });
      //   return;
      // }
      let ordertips = [];
      this.selects.forEach(element => {
        ordertips.push(element.returnNo);
      });
      if (this.selects.length == 0) {
        this.$message({ message: '请勾选至少一条售后的订单(发货前退款/仅退款等)' });
        return;
      }

      let cofirmMsg = '确认审核勾选的售后订单[' + ordertips.join(',') + ']，是否继续?';
      // let cofirmMsg = '确认审核选择的售后订单，是否继续?';
      // if (this.currRow.status == 0 || this.currRow.status == 10) {
      //   if (this.currRow.returnType == 0)
      //     cofirmMsg = '该售后单类型[发货前退款]，确认直接完结该售后单，是否继续?'
      //   else if (this.currRow.returnType == 20)
      //     cofirmMsg = '该售后单类型[仅退款]，确认该订单已退款，并完结该售后单，是否继续?'
      // }

      let that = this;
      this.$confirm(cofirmMsg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          confirmReturnOrders(this.selects).then((res) => {
            if (res.code === 200) {
              that.$alert(JSON.stringify(res.data), res.content);
              that.fetchs();
            } else {
              that.$alert(res.content, '提示');
            }
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "操作失败", });
        });
    },
    returnTypeChange() {
      console.log(this.forms.returnType);
      // if (this.forms.detailCode || this.forms.serialCode) {
      //   return; //已经有内容了，无需请求接口获取
      // }
      console.log(this.forms)
      //通过订单号获取原出库订单信息
      stockOriginData(this.forms).then((res) => {
        if (res.code === 200) { //通过原出库记录回填出库信息，可允许修改
          this.forms = { ...this.forms, ...res.data };
          // let stockBounds = res.data.filter(res => res.storeCode == '成品库');
          // let fitBounds = res.data.filter(res => res.storeCode == '礼品库' || (res.remark || '').indexOf('[赠品]') > -1);
          // if (stockBounds.length > 0) { //成品
          //   if (!this.forms.storeCode)
          //     this.forms.storeCode = stockBounds[0].storeCode;
          //   if (!this.forms.detailCode)
          //     this.forms.detailCode = stockBounds[0].detailCode;
          //   if (!this.forms.serialCode)
          //     this.forms.serialCode = stockBounds[0].serialCode;
          //   if (!this.forms.returnQty)
          //     this.forms.returnQty = Math.abs(stockBounds[0].stockNumber);
          //   if (!this.forms.returnFee)
          //     this.forms.returnFee = stockBounds[0].sellPrice;
          // }
          // if (fitBounds.length > 0) { //赠品
          //   if (!this.forms.fitStoreCode)
          //     this.forms.fitStoreCode = fitBounds[0].storeCode;
          //   if (!this.forms.fitDetailCode)
          //     this.forms.fitDetailCode = fitBounds[0].detailCode;
          //   if (!this.forms.fitSerialCode)
          //     this.forms.fitSerialCode = fitBounds[0].serialCode;
          // }
        }
      });
    },
    stockReturnEvent() {
      let checkedType = [];
      this.selects.forEach(element => {
        if (element.returnType == 10 || element.returnType == 30 || element.returnType == 50)
          checkedType.push(element);
      });
      if (checkedType.length == 0) {
        this.$message({ message: '请勾选至少一条发生退货的订单行(退货退款/以旧换新等，发货前退款或仅退款类型不支持退货入库)' });
        return;
      }
      this.$confirm("确认对勾选的售后单对应的货品进行退货入库, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          stockinReturnData(this.selects).then((res) => {
            if (res.code === 200) {
              this.$message({ type: "success", message: res.content, });
              this.$router.push("/stock/bills/instore?billType=客户退货&data=" + encodeURIComponent(JSON.stringify(res.data || '{}')) + "&v=1")
            }
            //     stockReturnOrder(this.currRow.id).then((res) => {
            //       if (res.code === 200) {
            //         that.$message({ type: "success", message: res.content, });
            //         that.fetchs();
            //       }
            //     });
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "操作失败", });
        });

    },
    openOrderEvent() {
      this.$refs.detailDialog.toggle();
    },
    saveOrderEvent() {
      console.log(this.forms);
      if (this.forms.returnType == undefined) {
        this.$message({ message: '请选择售后订单类型' });
        return;
      }
      if ((this.forms.channel || '') == '') {
        this.$message({ message: '请选择售后订单对应渠道' });
        return;
      }

      //发货前退货
      if (this.forms.returnType == 0) {
        this.forms.storeCode = '';
        this.forms.returnQty = 0;
        this.forms.returnFee = 0;
      }

      if (this.forms.returnType == 10 || this.forms.returnType == 30) {
        if (!this.forms.storeCode || !this.forms.serialCode || !this.forms.detailCode || this.forms.returnQty <= 0 || this.forms.returnFee <= 0) {
          this.$message({ message: '售后订单类型涉及到货品信息，请补充对应的库房及货品数量、价格信息' });
          return;
        }
        if (!this.forms.returnPostName || !this.forms.returnPostNo) {
          this.$message({ message: '售后订单类型涉及到货品信息，请补充用户寄回的快递信息' });
          return;
        }
      }
      this.forms.returnType = parseInt(this.forms.returnType);

      let that = this;
      this.$confirm('确认修改保存该售后单信息', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          saveReturnOrder(this.forms.id, this.forms).then((res) => {
            if (res.code === 200) {
              that.$message({ type: "success", message: res.content, });
              that.$refs.detailDialog.toggle();
            } else {
              that.$alert(res.content, '提示');
            }
          }, (res) => {
            that.$alert(res.content, '提示');
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "审核失败", });
        });
    },

    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
    seleHandler(rows) {
      this.selects = rows;
    },
    noGoods() {
      if (this.forms.returnType == 0) return true;
      if (this.forms.returnType == 10) return false;
      if (this.forms.returnType == 20) return true;
      if (this.forms.returnType == 30) return false;

      return false;
    },


    openUploadDialog(row) {
      let that = this;
      that.$refs.uploadDialog.toggle();
    },
    //添加文件事件
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    //删除文件事件
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    //超出个数事件
    handleExceed(file, fileList) {
      this.$message({
        message: "每次只能上传一个文件",
        type: "warning",
      });
    },

    //上传操作
    submitUpload(file) {
      this.loading = true;
      var dataList = new FormData();
      dataList.append(file.file.name, file.file);
      dataList.append("fileType", "excel");
      dataList.append("dataType", "ImportReturn");
      this.$refs.upload.clearFiles();

      uploader(dataList)
        .then((res) => {
          if (res.code === 200) {
            this.$message({ message: "上传成功", type: "success", });
            this.dataId = res.data[0].id;
          } else
            this.$message.error("上传失败，请重试");
        })
        .then(() => {
          this.loading = false;
          importOrderReturn(this.departmentId, this.templateValue, this.dataId).then(
            (res) => { this.fetchs(); this.$alert(res.content, '提示'); },
            (err) => { this.$alert(err.content, '提示'); }
          );
        });

      this.fileList = [];
    },
    download() {
      window.location.href = process.env.VUE_APP_BASEURL + "static/order/return/" + this.templateValue + ".xlsx";
    }
  },
  watch: {
    companyId: function (newValue, oldValue) {
      if (newValue && newValue != oldValue)
        this.$util.setLocalstorage('companyIdStr', newValue);
      else
        this.$util.setLocalstorage('companyIdStr', '');
    },
    pager: function (newValue, oldValue) {
      this.$util.setPageCache('keyword', newValue.keyword);
      if (newValue.page)
        this.$util.setPageCache('page', newValue.page);
      if (newValue.rows)
        this.$util.setPageCache('rows', newValue.rows);
      if (newValue.sidx)
        this.$util.setPageCache('sidx', newValue.sidx);
      if (newValue.sord)
        this.$util.setPageCache('sord', newValue.sord);
    }
  },
  created: function () {
    this.importDateRange = this.$util.getCurrentDate('recentHalfYear');

    let status = this.$route.meta.status;
    this.status = (status != undefined) ? status.toString() : '';

    let companyIdStr = this.$util.getLocalstorage('companyIdStr');
    if (companyIdStr && companyIdStr.length > 0) {
      this.companyId = companyIdStr;
    }

    this.querys.keyword = this.$util.getPageCache('keyword');

    this.pager.page = parseInt(this.$util.getPageCache('page') || 1);
    this.pager.rows = parseInt(this.$util.getPageCache('rows') || 10);

    let sidx = this.$util.getPageCache('sidx');
    if (sidx) this.pager.sidx = sidx;
    let sord = this.$util.getPageCache('sord');
    if (sord) this.pager.sord = sord;


    this.$kaung.companys().then((res) => {
      this.companys = res;
      this.fetchs();
    });

    this.$kaung.dictionary("stock", "store").then(res => this.dict.stores = res);
    this.$kaung.dictionary("stock", "channel").then((res) => { this.dict.channels = res; });
    this.$kaung.dictionary("stock", "platform").then((res) => { this.dict.platforms = res; });
  }
};
</script>

<style lang="scss" scoped>
.space20 {
  margin-right: 20px;
  color: #000;
}

.space40 {
  margin-right: 40px;
  color: #000;
}

.redbox {
  color: red;
}
</style>