<template>
  <!--费用报销单-->
  <!-- <el-button size="mini" style="position: relative;right: 20px;top: 4px;float: right;z-index: 9;">
            保存
        </el-button> -->

  <el-tabs type="border-card" v-model="tabName">
    <el-tab-pane name="workform">
      <template #label>
        <span> 单据信息 </span>
        <span style="display: none;">{{ user.companyID + "-" + user.departmentID }}</span>
      </template>

      <div class="toolbox">
        <el-button color="#626aef" plain @click="save" v-if="startNode">保存草稿 </el-button>
        <el-button type="primary" plain @click="openAuditDialog" v-if="startNode">提交审核</el-button>

        <el-button type="primary" plain @click="openAuditDialog" v-if="opts.status == 10">审批</el-button>


        <el-button type="success" plain @click="print">打印</el-button>
        <el-button @click="back">取消</el-button>

        <el-button type="warning" plain @click="returnEvent" v-if="opts.status == 10">强制退回</el-button>
      </div>

      <div ref="printRef" class="pagebox horizbox">
        <!-- <expense></expense> -->
        <!-- @initForms="initForms(forms, $event)" -->
        <component ref="pageRef" :is="pageHolder" :userinfo="userInfo"
          :opts="opts" :formdata="forms" @initOpts="initOpts(opts, $event)" />
      </div>
    </el-tab-pane>
    <el-tab-pane name="fileform">
      <template #label>
        <span> 表单附件 </span>
      </template>

      <div id="fileform">
        <el-upload
          :action="apiUrl + 'BaseUploader/Basic?dataType=' + opts.formType + '&dataID=' + opts.uniqueCode + '&fileType=form&fileCode=' + opts.billCode"
          list-type="picture-card" :on-success="uploadHandleSuccess" :on-remove="removeHandle"
          :on-preview="previewHandle" :before-upload="uploadHandleBefore" :file-list="opts.files">
          <el-icon>
            <Plus />
          </el-icon>
          <template #file="{ file }">
            <img v-if="file.ext == 'jpg' || file.ext == 'jpeg' || file.ext == 'png' || file.ext == 'bmp'"
              class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
            <div style="font-size: 1em; width: 100%; text-align: center; font-weight: 800; display: flex;
                align-items: center;">
              <span style="overflow-wrap:anywhere">{{ file.name }}</span>
            </div>
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview" @click="previewHandle(file)" title="预览">
                <el-icon><zoom-in /></el-icon>
              </span>
              <span class="el-upload-list__item-delete" @click="downloadHandle(file)" title="下载">
                <el-icon>
                  <Download />
                </el-icon>
              </span>
              <span class="el-upload-list__item-delete" @click="removeHandle(file)" title="删除">
                <el-icon>
                  <Delete />
                </el-icon>
              </span>
            </span>
          </template>
        </el-upload>
      </div>
    </el-tab-pane>
    <el-tab-pane name="flownode">
      <template #label>
        <span> 审批记录 </span>
      </template>

      <div id="flownode">
        <el-timeline>
          <el-timeline-item v-for="(record, index) in records" :key="index" :timestamp="record.createDate" center
            :color='(record.actionCode == 10) ? "#79bbff" : (record.actionCode == 50) ? "#67c23a" : record.actionCode == -10 ? "#e6a23c" : record.actionCode == -50 ? "#f56c6c" : ""'
            placement="top">
            <el-card>
              <p><b>审批状态：</b>
                [{{ record.createUserName }}]
                <span v-if="record.actionCode == 0">草稿</span>
                <span style="color:#79bbff" v-else-if="record.actionCode == 10">通过</span>
                <span style="color:#67c23a" v-else-if="record.actionCode == 50">结束</span>
                <span style="color:#e6a23c" v-else-if="record.actionCode == -10">退回</span>
                <span style="color:#f56c6c" v-else-if="record.actionCode == -50">拒绝</span>
                <span v-else>未知</span>
              </p>
              <p><b>审批人员：</b>{{ record.sourceUserName + ' --> ' + record.targetUserName }}</p>
              <p><b>审批动作：</b>{{ record.sourceNodeName + ' --> ' + record.targetNodeName }}</p>
              <p><b>审批意见：</b>{{ record.remark || '无' }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-tab-pane>

    <el-tab-pane name="flowdraw">
      <template #label>
        <span> 审批流程图 </span>
      </template>

      <div id="flowdraw">
        <drawapp ref="drawapp1" :data="drawdata" :height="500" :width="800" :lang="lang" />
      </div>
    </el-tab-pane>
  </el-tabs>

  <static-dialog ref="flowAuditDialog" :ok="submit" width="650px" class="dialog" title="提交流程">
    <flowAudit ref="flowAudit" :instance="opts"></flowAudit>
  </static-dialog>
</template>

<script>
import { shallowRef } from 'vue'
// 通过定义一个函数的方式来引入，传入一个参数，返回组件。
const loadComponent = value => import(`@/views/workflow/instance/${value}.vue`);

// import expense from "@/views/workflow/instance/expense.vue";

import drawapp from "@/components/flowDraw";
import flowAudit from "@/views/workflow/flowAudit.vue";
import StaticDialog from "@/components/static-dialog.vue";
import { getBillCode, fetchConfigerByCode, submitInstance, getInstance, fetchRecords, returnInstance } from "@/api/workflow";
import { fetchFileList, delFile } from "@/api/basic";
export default {
  name: "flowForm",
  components: { StaticDialog, flowAudit, drawapp },
  data() {
    return {
      lang: "zh",
      apiUrl: '',

      pageHolder: null,
      formCode: '',

      tabName: "workform",
      opts: {},
      forms: undefined,
      //files: [],
      userInfo: {},
      records: [],
      drawdata: {},

      //uploadHeaders: { "Authorization": sessionStorage.getItem('token_type') + " " + sessionStorage.getItem('access_token') }
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async check(action) {
      return this.$refs.pageRef.formCheck(action);
    },
    //保存草稿（不校验页面及参数）
    save() {
      this.check().then(r => {
        //if (r)  //保存草稿的时候不校验参数，允许直接保存
        {
          this.opts.formUrl = location.pathname;
          this.opts.formData = JSON.stringify(this.$refs.pageRef.getForms() || {});
          this.opts.fileNumber = this.opts.files.length;

          submitInstance(this.opts).then((res) => {
            if (res.code === 200) {
              this.$message({ type: "success", message: res.content, });
              // setTimeout(function(){
              //   location.reload();
              // },2000);
              this.forms = {};
              this.$router.push("/workflow/instance");
              //history.back()
            }
          });
        }
      });

    },
    submit() {
      let data = this.$refs.flowAudit.getAuditor();
      if (!data) {
        this.$message.error("请选择对应的审核操作");
        return;
      }

      this.check().then(r => {
        if (!r) {
          this.$message.error("请补充表单的完整信息");
          return;
        }
        else {
          if (data.status == 0 || data.status == '') {
            this.$message.error("请选择该节点的审核操作");
            return;
          }
          if ((data.nextNode.clazz != 'end' && data.notes.status == '10') && data.operator.length == 0) {
            this.$message.error("请选择下节点的审批人");
            return;
          }
          let auditorIds = []; let auditorNames = [];
          data.operator.forEach(p => {
            auditorIds.push(p.id);
            auditorNames.push(p.realName);
          });

          this.opts.operatorID = auditorIds.join(',');
          this.opts.operatorName = auditorNames.join(',');
          this.opts.nodeID = data.nextNode.id;
          this.opts.nodeName = data.nextNode.label;
          this.opts.status = parseInt(data.notes.status + '');
          this.opts.remark = data.notes.remark;

          this.opts.formUrl = location.pathname;
          this.opts.formData = JSON.stringify(this.$refs.pageRef.getForms() || {});
          this.opts.fileNumber = this.opts.files.length;

          submitInstance(this.opts).then((res) => {
            if (res.code === 200) {
              //审核完毕之后可以设置表单的回调
              if (this.$refs.pageRef.callback) {
                this.$refs.pageRef.callback(res.data);
              }

              this.$message({ type: "success", message: res.content, });
              // setTimeout(function () {
              //   location.reload();
              // }, 2000);
              this.forms = {};
              this.$router.push("/workflow/flowHistory");
              //history.back()
            }
          });
        }
      });
    },
    returnEvent() {
      this.$confirm("管理员有权将审判中的流程强制退回, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          returnInstance(this.opts.id).then((res) => {
            if (res.code === 200) {
              //审核完毕之后可以设置表单的回调
              if (this.$refs.pageRef.callback) {
                this.$refs.pageRef.callback(res.data);
              }

              this.$message({ type: "success", message: res.content, });
              // setTimeout(function () {
              //   location.reload();
              // }, 2000);
              this.forms = {};
              this.$router.push("/workflow/flowHistory");
              //history.back()
            }
          });
        })

    },
    openAuditDialog() {
      // if (!this.check('open'))
      //   return;

      if (this.userInfo.roles.indexOf('Admin') >= 0 && this.opts.operatorID && this.opts.operatorID.indexOf(this.userInfo.userID) == -1) {
        this.$alert('待审批人：' + this.opts.operatorName + '。与当前操作人信息不符； 允许管理员强制审核通过，本次操作记录将留痕');
      }
      else if (this.opts.operatorID && this.opts.operatorID.indexOf(this.userInfo.userID) == -1) {
        this.$message({ type: 'error', message: '审批人信息不符，当前待审批人：' + this.opts.operatorName, });
        return;
      }

      this.$refs.flowAuditDialog.toggle();
    },
    print() {
      let eles = document.querySelectorAll('.el-input');
      for (let i = 0; i < eles.length; i++) {
        let ele = eles[i];
        ele.innerHTML = "<div class='print-input'>" + ele.querySelectorAll('input')[0].value + "</div>";
      }
      eles = document.querySelectorAll('.el-textarea');
      for (let i = 0; i < eles.length; i++) {
        let ele = eles[i];
        ele.innerHTML = "<div class='print-input' style='overflow-wrap: break-word; word-break: break-all;white-space:break-spaces'>" + ele.querySelectorAll('textarea')[0].value + "</div>";
      }

      let rmvs = document.querySelectorAll('.el-input-number__decrease');
      for (let i = 0; i < rmvs.length; i++) {
        rmvs[i].remove();
      }
      rmvs = document.querySelectorAll('.el-input-number__increase');
      for (let i = 0; i < rmvs.length; i++) {
        rmvs[i].remove();
      }
      rmvs = document.querySelectorAll('.el-button');
      for (let i = 0; i < rmvs.length; i++) {
        rmvs[i].remove();
      }

      let printHtml = this.$refs.printRef.innerHTML; //获取指定打印区域
      let oldHtml = window.document.body.innerHTML;
      // 构建新网页
      window.document.body.innerHTML = printHtml;
      // 调用打印功能
      window.print();
      window.document.body.innerHTML = oldHtml; //重新给页面内容赋值；

      location.reload();

      return false;
    },

    uploadHandleBefore(file) {
      if (!this.opts.uniqueCode)
        return false;

      //允许所有人在流程中上传文件
      //console.log(this.opts);
      //如果是管理员角色可以新补充上传，无需草稿状态才能上传
      // let role = this.$store.state.userInfo.roles.filter(x => x.toLowerCase() == 'admin');
      // if (role.length == 0 && (this.opts.status != 0 && this.opts.status != -10)) {
      //   this.$message({ type: 'info', message: '不允许上传正在审批过程中的单据', });
      //   return false;
      // }
    },
    removeHandle(file) {
      let role = this.$store.state.userInfo.roles.filter(x => x.toLowerCase() == 'admin');
      if (role.length == 0 && (this.opts.status != 0 && this.opts.status != -10)) {
        this.$message({ type: 'info', message: '不允许删除正在审批过程中的单据', });
        return false;
      }

      delFile(file.id).then((res) => {
        this.$message({ type: res.type, message: res.content });
        this.getFileList();
      });
      //this.files = this.files.filter(q => q.id != file.id);
    },
    uploadHandleSuccess(res, file, fileList) {
      this.$message({ type: res.type, message: res.content, });
      fileList = [];
      this.getFileList();
      // if (res.code == 200) {
      // } else {
      //   fileList = fileList.pop()
      // }
    },
    previewHandle(res) {
      //window.open(res.url);
      window.open('/preview?id=' + res.id);
    },
    downloadHandle(res) {
      console.log(res);
      window.open(res.url);
    },
    getFileList() {
      fetchFileList(this.opts.uniqueCode).then((res) => {
        this.opts.files = res.data;
      });
    },
    getRecords(id) {
      fetchRecords(id).then((res) => {
        this.records = res.data;
      });
    },

    //取子页面的 默认值
    initOpts() {
      //如果是新创建的页面
      if ((this.id || '').length == 0) {
        this.opts = this.$refs.pageRef.getInitOpts();
        this.opts.status = 0;
        console.log('1', this.opts);

        fetchConfigerByCode(this.opts.flowCode).then((res) => {
          this.drawdata = JSON.parse(res.data.flowData);

          getBillCode(res.data.prefixCode || this.opts.formType).then((res) => {
            this.opts.billCode = res.data.billCode;
            this.opts.uniqueCode = res.data.uniqueCode;

            this.getFileList();
          });
        });

        setInterval(() => {
          this.opts.createDate = this.$util.fmtDate(new Date(), "yyyy-MM-dd HH:mm:ss");
          this.opts.createUserName = this.$store.getters.getUserInfo.userName;
          //console.log(this.opts);
        }, 1000);
      }
    },
    // //取子页面的 默认值
    // initForms() {
    //   this.forms = this.$refs.pageRef.getInitForms();
    // }
  },
  created() {
    this.id = this.$route.query.id;
    if (!this.id) {
      this.formCode = this.$route.query.page || '';
    }
    else {
      //加载已经保存过的数据
      getInstance(this.id).then((res) => {
        if (res.code === 200) {
          this.opts = res.data;

          this.formCode = this.opts.formCode;
          this.forms = JSON.parse(this.opts.formData);
          this.getFileList();

          this.getRecords(this.opts.id);

          this.drawdata = JSON.parse(res.data.flowData);
        }
      });
    }

    this.apiUrl = process.env.VUE_APP_BASEURL;
  },
  watch: {
    userInfo: {
      handler(newValue, oldValue) {
        //加载到了登录的用户信息
        //console.log('userInfo', newValue);
      },
      deep: true
    },
    formCode: function (newValue, oldValue) {
      if ((newValue || '').length > 0) {
        loadComponent(newValue).then(component => {
          this.pageHolder = shallowRef(component.default);
          this.pageHolder.mounted = function () {
            this.$emit('initOpts');
            //this.$emit('initForms');
          }
        });
      }
    }
  },
  computed: {
    user() {
      let user = this.$store.state.userInfo;
      this.userInfo = user;
      return user;
    },
    startNode() {
      //草稿或者退回状态
      if (this.opts.status == 0 || this.opts.status == -10 || this.opts.status == '') {
        return true;
      }
      return false;
    },
    endNode() {
      if (this.opts.status == 50) {
        return true;
      }
      return false;
    },
  },
  mounted: function () {

  },

};
</script>

<style lang="scss" scoped>
.el-card__body>p {
  line-height: 25px;
}

.toolbox {
  position: absolute;
  right: 10px;
  z-index: 9;
}

.pagebox {
  max-width: 960px;
  margin: 0 auto;
}

/* 在小于或等于 960 像素的屏幕上 */
@media screen and (max-width: 960px) {
  .pagebox {
    padding-top: 35px;
  }
}


:deep .form-title {
  text-align: center;
  margin: 10px auto;
  padding-bottom: 10px;
  color: #000;
  font-size: 1.8em;
  font-weight: 400;
  border-bottom: 4px double #000;
  max-width: 410px;
  letter-spacing: 25px;
}

:deep .form-no {
  text-align: right;
  margin: 15px 0;
}

:deep .print-input {
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: break-spaces;
  display: inline-block;
}

:deep .itemPanel,
:deep .detailPanel {
  display: none;
}


@media print {

  // @page {     /*A4: 210mm × 297mm*/
  //      size: 195mm 275mm;  /* or size: A4;  or size: 794px 1123px; */
  //   }
  :deep .no-print {
    display: none;
  }
}
</style>