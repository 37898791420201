<template>
  <div class="static-dialog load-dialog">
    <el-dialog :title="title" v-model="visible" :show-close="false" :close-on-click-modal="false" destroy-on-close
      top="5vh" center style="width:95%; max-width:560px;">
      <el-form :model="forms" :rules="rules" ref="forms" label-width="100px" size="large">
        <el-row>
          <el-col :xs="24">
            <el-form-item label="旧密码" prop="oldpassword">
              <el-input v-model="forms.oldpassword" placeholder="请输入账户原密码" autocomplete="off" style="max-width: 250px"
                show-password></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24">
            <el-form-item label="新密码" prop="newpassword">
              <el-input v-model="forms.newpassword" placeholder="请输入账户新密码" autocomplete="off" style="max-width: 250px"
                show-password></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24">
            <el-form-item label="确认密码" prop="confirmpassword">
              <el-input v-model="forms.confirmpassword" placeholder="请重复输入账户新密码" autocomplete="off"
                style="max-width: 250px" show-password></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="visible = false" size="large">取 消</el-button>
          <el-button type="primary" @click="resetPwd()" size="large">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>


<script>
import { resetUserPwd } from "@/api/sys";
import { logout } from "@/api/login";

export default {
  name: "reset",
  components: {},
  props: {
    user: {
      type: Object,
      default: {},
    },
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "重置密码",
    },
  },
  data() {
    return {
      forms: {},
      rules: {
        oldpassword: [
          { min: 0, max: 30, message: "请输入账号原密码", trigger: "blur" },
        ],
        newpassword: [
          { min: 4, max: 30, message: "密码长度不符合要求", trigger: "blur" },
        ],
        confirmpassword: [
          { min: 4, max: 30, message: "密码长度不符合要求", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    resetPwd() {
      this.forms.id = "";
      if (this.user && this.user.id) this.forms.id = this.user.id;
      this.$refs.forms.validate((valid) => {
        if (!valid) return;

        if (!!!this.forms.newpassword) {
          this.$message.error("请输入新旧密码~");
          return;
        }

        if (this.forms.newpassword != this.forms.confirmpassword) {
          this.$message.error("两次输入新密码不一致~");
          return;
        }

        //var reg1 = new RegExp(/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,}/);
        //新密码需要大小写字母、数字、特殊字符以及至少8位数长度的组合
        if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/.test(this.forms.newpassword) || this.forms.newpassword.indexOf("12345") > -1
          || this.forms.newpassword.indexOf("abcd") > -1) {
          this.$message.error("新密码过于简单请重新设置(6位及以上且需要字母+数字组合)~");
          return;
        }

        resetUserPwd(this.forms).then((res) => {
          if (res.code === 200) {
            this.$message({ type: "success", message: res.content });
            setTimeout(function () {
              logout();
            }, 1000);
          }
        });
      });
    },
  },
  created() {
  },
  mounted() { },
};
</script>
