<template>
  <div class="billsdetial">
    <div class="table tableprint" ref="print">
      <el-table :data="list" stripe border size="small" style="width: 100%">
        <el-table-column align="center" size>
          <template #header>
            <el-row type="flex">
              <el-form-item label="所属公司">
                <el-col>
                  <!-- <el-cascader v-model="companyID" :options="companys" style="width: 160px" /> -->
                  <el-tree-select ref="companys" v-model="form.companyId" :data="companys" clearable check-strictly
                    :render-after-expand="false" style="min-width: 210px;" />
                </el-col>
              </el-form-item>

              <el-form-item label="转入库房" style="margin-left:20px;">
                <el-col>
                  <el-select v-model="form.storeCode" placeholder="库房" style="width: 110px">
                    <el-option v-for="item in dict.stores" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-form-item>

              <el-form-item label="转入渠道" style="margin-left:20px;">
                <el-col>
                  <el-select v-model="form.channel" placeholder="渠道" style="width: 110px">
                    <el-option v-for="item in dict.channels" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-form-item>

              <el-form-item label="调拨时间" style="margin-left:20px;">
                <el-col>
                  <el-input v-model="form.billDate" disabled style="width: 160px" placeholder="自动生成"> </el-input>
                </el-col>
              </el-form-item>

              <el-form-item label="调拨单号" style="margin-left:20px;">
                <el-col>
                  <el-input v-model="form.billCode" autocomplete="off" style="width: 140px" placeholder="自动生成">
                  </el-input>
                </el-col>
              </el-form-item>
            </el-row>
          </template>
          <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>
          <el-table-column prop="detailCode" label="系统款号(SKU)" width="130"> </el-table-column>
          <el-table-column prop="serialCode" label="批次号" width="130"> </el-table-column>
          <el-table-column prop="brandSerialCode" label="品牌批次号" width="130"> </el-table-column>
          <el-table-column prop="detailName" label="名称" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="storeCode" label="原实际库房" width="90">
            <template #default="scope">
              <el-select v-model="scope.row.storeCode" placeholder="库房" size="small" style="width:100%"
                @change="changeBound(scope.row)">
                <el-option v-for="item in dict.stores" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="channel" label="原渠道" width="100">
            <template #default="scope">
              <el-select v-model="scope.row.channel" placeholder="渠道" size="small" style="width: 100%"
                @change="changeBound(scope.row)">
                <el-option v-for="item in dict.channels" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column prop="stockNumber" label="本次转移数量" width="120">
            <template #default="scope">
              <el-input v-model="scope.row.stockNumber" autocomplete="off" type="number" style="width: 90px"
                size="small">
              </el-input>
            </template>
          </el-table-column>

          <el-table-column prop="stockRemain" label="总数量" width="70"> </el-table-column>

          <el-table-column prop="id" label="" width="80">
            <template #default="scope">
              <el-button @click="dele(scope.row)" type="primary" link size="small">[删除]</el-button>
            </template>
          </el-table-column>

        </el-table-column>
      </el-table>
      <el-row type="flex" justify="center">
        <div style="margin-top: 15px">
          <el-button type="info" @click="openInput()" icon="plus">
            添加要调拨的货品(可批量)
          </el-button>
        </div>
      </el-row>
      <el-row type="flex" class="inputfeed" justify="center">
        <el-col :span="5">
          操作：
          <el-input size="small" style="width: 60%" v-model="form.handoverName"></el-input>
        </el-col>
        <el-col :span="5">
          交接：
          <el-input size="small" style="width: 60%" v-model="form.reviewerName"></el-input>
        </el-col>
        <el-col :span="5">
          复核：
          <el-input size="small" style="width: 60%" v-model="form.operatorName"></el-input>
        </el-col>
        <el-col :span="5">
          财务：
          <el-input size="small" style="width: 60%" v-model="form.financerName"></el-input>
        </el-col>
      </el-row>
    </div>
    <div class="billsbutton">
      <el-button type="primary" @click="save()" icon="ArrowRight">
        调拨 <i class="el-icon-arrow-right"> </i>
      </el-button>
    </div>
  </div>
</template>
<script>
import { submitRealTransfer, fetchBillCode, fetchBoundBySerialCode } from "@/api/stock";
export default {
  name: "Realer",
  emits: ["dialogEvent"],
  data() {
    return {
      loading: false,
      list: [],

      dict: {
        stores: [],
        channels: [],
      },

      form: {
        billType: '库存调拨',
        storeCode: "", //转入库房
        channel: "", //转入渠道
        billDate: "", //时间
        billCode: "", //入库单据号

        companyId: ''
      },

      //companyID: [],
      // props: {
      //   checkStrictly: true,
      // },
      companys: []
    };
  },
  methods: {
    openInput() {
      //this.form.companyID = this.companyID[this.companyID.length - 1];
      if ((this.form.companyId || '').length == 0) {
        this.$message({
          type: "warning",
          message: "请选择货品所属公司",
        });
        return;
      }
      if ((this.form.storeCode || '').length == 0 || (this.form.channel || '').length == 0) {
        this.$message({
          type: "warning",
          message: "请选择要转入的库房及渠道",
        });
        return;
      }

      this.$prompt("请输入系统批次号(货号)", "提示", {
        inputPlaceholder: '可批量输入 如有多个批次号用空格隔开',
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "promptDialog",
      }).then(({ value }) => {
        if (value) {
          //var serialArr = value.split(' ');
          this.fetchBound(value);
        }
      });
    },
    changeBound(row) {
      this.fetchBound(row.serialCode, row.storeCode, row.channel, row.companyId)
    },
    fetchBound(serialCode, storeCode, channel, companyId) {
      fetchBoundBySerialCode(serialCode, storeCode, channel, companyId).then((res) => {
        if (res.data.length == 0) {
          this.$message({
            type: "warning",
            message: "未找到该货品信息: " + serialCode,
          });
          return;
        }

        let results = res.data;
        results.forEach((item) => {
          this.list = this.list.filter(res => res.serialCode != item.serialCode);
          if (this.list.filter(res => res.serialCode == item.serialCode).length == 0) {
            this.list.push(item);
          }
        });
        //原单个处理
        // this.list = this.list.filter(function (item) {
        //   return item.serialCode != data.serialCode;
        // })
        // this.list.push(data);
        // this.list.sort((a, b) => {
        //   const nameA = a.serialCode.toUpperCase()
        //   const nameB = b.serialCode.toUpperCase()
        //   if (nameA < nameB) return -1
        //   if (nameA > nameB) return 1
        //   return 0
        // })
      });
    },
    save() {
      let that = this;
      if (this.list.length <= 0) {
        this.$message({
          message: "请输入至少一条数据",
          type: "warning",
        });
        return;
      }

      console.log(this.list);
      this.form.channelCode = this.form.channel;
      //this.form.companyID = this.companyID[this.companyID.length - 1];
      submitRealTransfer({ bill: this.form, bounds: this.list }).then((res) => {
        that.$message({
          type: res.type,
          message: res.content,
        });
        this.$emit("dialogEvent", "realerDialogVisible");
      });
    },
    dele(row) {
      console.log(this.list);
      this.list = this.list.filter(function (item) {
        return item.id != row.id;
      })
    }
  },
  mounted() {
    this.$kaung.dictionary("stock", "store").then(res => this.dict.stores = res);
    this.$kaung.dictionary("stock", "channel").then((res) => { this.dict.channels = res; });

    this.$kaung.companys().then((res) => {
      this.companys = res;
    });

    let that = this;
    fetchBillCode({
      billType: "库存调拨",
      prev: "DB",
    }).then((res) => {
      that.form.billCode = res.data;
      that.form.billDate = that.$util.fmtDate(new Date());
    });
  },
};
</script>
<style lang="less">
.el-message-box__wrapper {
  z-index: 3001 !important;
}

.v-modal {
  z-index: 3000 !important;
}
</style>