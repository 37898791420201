<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">进销存</el-breadcrumb-item>
        <el-breadcrumb-item>组装单管理</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="query" ref="query" label-width="90px" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col style="width: 140px">
            <el-input v-model="query.keyword" @keyup.enter="fetchs" prefix-icon="Search"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="所属公司">
          <el-col>
            <!-- <el-cascader v-model="companyId" :options="companys" clearable @change="fetchs" /> -->
            <el-tree-select ref="companys" v-model="companyId" :data="companys" @change="fetchs();" clearable
              check-strictly :render-after-expand="false" style="min-width: 210px;" />
          </el-col>
        </el-form-item>

        <el-form-item label="单据时间">
          <el-col>
            <el-date-picker v-model="dateRange" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"
              unlink-panels :shortcuts="$util.getShortCuts()" @change="fetchs()" style="max-width:210px;">
            </el-date-picker>
          </el-col>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="操作类型" prop="billType">
          <el-col>
            <el-select v-model="billType" placeholder="请选择操作类型" clearable @change="fetchs" style="width: 150px">
              <el-option v-for="item in dict.billTypes" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="单据状态" prop="status">
          <el-col>
            <el-select v-model="status" placeholder="请选择单据状态" @change="fetchs" clearable style="width: 150px">
              <el-option key="待审核" label="待审核" value="0"></el-option>
              <el-option key="已审核" label="已审核" value="1"></el-option>
              <el-option key="已取消" label="已取消" value="-1"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="Search" @click="fetchs()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button type="primary" icon="FirstAidKit" @click="openAssDialog">
        成品组装
      </el-button>
      <el-button type="primary" icon="FolderDelete" @click="openDisDialog">
        成品拆解
      </el-button>
    </div>

    <basic-table ref="table" class="table" :query="query" @handler="fetchs">
      <template v-slot:status="scope">
        <el-link v-if="scope.row.status == 0" type="primary" size="small" disabled>待审核</el-link>
        <el-link v-else-if="scope.row.status == 1" type="success" size="small" disabled>已审核</el-link>
        <el-link v-else-if="scope.row.status == -1" type="danger" size="small" disabled>已取消</el-link>
        <el-link v-else type="info" size="small" disabled>其他</el-link>
      </template>

      <template v-slot:toolbar="scope">
        <el-link @click="watch(scope.row)" type="primary" size="small">{{ scope.row.status == 0 ? "去审核" : "查看"
          }}</el-link>

        <el-link @click="delRow(scope.row)" type="primary" size="small" v-if="scope.row.status == 0"
          style="margin-left:10px;">删除</el-link>
      </template>
    </basic-table>
  </el-card>
</template>

<script>
import { fetchBillCode } from "@/api/stock";
import { fetchSkuList } from "@/api/product";
import StaticDialog from "@/components/static-dialog.vue";
import BasicTable from "@/components/basic-table.vue";
export default {
  name: "StockBills",
  components: { StaticDialog, BasicTable },
  data() {
    return {
      dateRange: [],
      query: {
        keyword: "",
        columns: [
          { type: "selection", width: "55" },
          { type: "index", label: "序号" },
          { prop: "billType", label: "单据类型", sortable: true, searchable: true, width: "90", showOverflowTooltip: true, },
          { prop: "billCode", label: "单据编号", sortable: true, searchable: true, width: "130", showOverflowTooltip: true, },
          { prop: "storeCode", label: "库房", sortable: true, searchable: true, width: "80", showOverflowTooltip: true, },
          { prop: "netCode", label: "关联单号", sortable: true, searchable: true, width: "110", showOverflowTooltip: true },
          { prop: "productNumber", label: "数量", sortable: true, searchable: true, width: "70", },
          { prop: "costTotal", label: "金额", sortable: true, width: "90", },
          { prop: "billDate", label: "单据时间", width: "140", sortable: true, showOverflowTooltip: true },
          { type: "template", prop: "status", label: "状态", width: "60", searchable: true, },
          { prop: "operatorName", label: "操作", sortable: true, searchable: true, width: "70", },
          { prop: "handoverName", label: "交接", sortable: true, searchable: true, width: "70", },
          { prop: "reviewerName", label: "复核", sortable: true, searchable: true, width: "70", },
          { prop: "financerName", label: "财务", sortable: true, searchable: true, width: "70", },
          { prop: "reason", label: "单据备注", searchable: true, width: "220",showOverflowTooltip: true },

          { prop: "createDate", label: "创建时间", width: "150", sortable: true, showOverflowTooltip: true },
          { prop: "createUserName", label: "创建人", sortable: true, searchable: true, width: "80", },
          { type: "toolbar", label: "操作", width: "140" },
        ],
        filters: [],
        sidx: "billDate",
        sord: "desc"
      },

      status: '',
      companys: [],
      companyId: '',
      billType: '',
      dict: {
        billTypes: []
      },
    };
  },
  mounted() {
    //kaung插件初始化
    this.$kaung.init(this.$options.name);

    this.$kaung.dictionary("stock", "otherbound").then((res) => { this.dict.billTypes = res; });
    this.$kaung.companys().then((res) => {
      this.companys = res;
      this.fetchs();
    });
  },
  methods: {
    fetchs() {
      if (this.billType) {
        this.query.filters.push({
          name: "billType",
          value: this.billType,
          operate: 0,
        });
      }
      else {
        let billTypes = [];
        this.dict.billTypes.forEach((res) => { billTypes.push(res.value) });
        this.query.filters.push({
          name: "billType",
          value: billTypes.join(','),
          operate: 6,
        });
      }

      let { data, records, total, ...params } = { ...this.query };
      this.$kaung.search(params).then((res) => {
        this.query = { ...res.data };
      });
    },
    watch(row) {
      if (row.status == 1) //审核通过
        this.$router.push({ path: "detail", query: { billCode: row.billCode } });
      else
        this.$router.push({ path: "assembleDetail", query: { id: row.id } });
    },
    openAssDialog() {
      this.$router.push({ path: "assembleDetail", query: {} });
    },
    openDisDialog() {
      this.$message({ type: "info", message: "拆解单功能暂不支持，等待后续开发上线" });
    },
  },
  watch: {
    companyId: function (newValue, oldValue) {
      if (newValue && newValue != oldValue)
        this.$util.setLocalstorage('companyIdStr', newValue);
      else
        this.$util.setLocalstorage('companyIdStr', '');
    },
    billType: function (newValue, oldValue) {
      if (newValue != oldValue)
        this.$util.setPageCache('billType', newValue);
    },
    status: function (newValue, oldValue) {
      if (newValue != oldValue)
        this.$util.setPageCache('status', newValue);
    }
  },
  created: function () {
    let companyIdStr = this.$util.getLocalstorage('companyIdStr');
    if (companyIdStr != '' && (this.companyId == null || this.companyId.length == 0)) {
      this.companyId = companyIdStr;
    }
    this.query.keyword = this.$util.getPageCache('keyword');
    this.billType = this.$util.getPageCache('billType') || "";
    this.status = this.$util.getPageCache('status');
  }
};
</script>

<style lang="less"></style>